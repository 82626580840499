import {IRegionConfigItem} from '@microsoft/smart-parking-coreui/lib/models/uiModel/RegionConfig';

export const defaultRegion = 'US';
export const defaultRegionConfig: IRegionConfigItem = {
  regionId: 'US',
  regionName: 'United States & Canada',
  policyCheckLink: 'https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=MSPOLICY-510345001-5',
  carpoolPolicyLink: 'https://microsoft.sharepoint.com/teams/TransportationRedmond/SitePages/GettingToWorkCarpool.aspx',
  faqLink: 'https://microsoftapc.sharepoint.com/teams/Parking/SitePages/Parking-FAQs.aspx',
  showV2UI: false,
  carpoolEnabled: true,
  showMyHubAwareness: true,
  enabled: true,
  vehicleTypeList: 'Car,Motorcycle,Truck/Pick-up,Van',
  registrationTypeList: 'Personal,Rental,Service,Sponsored,Visitor',
  fuelTypeList: 'Petrol,Diesel,EV,Hybrid',
};
export const getRegionConfigById = (regionId: string, regionConfigArr: IRegionConfigItem[]): IRegionConfigItem => {
  const regionConfig = regionConfigArr.find(config => config.regionId === regionId && config.enabled);
  if (regionConfig) {
    return regionConfig;
  } else {
    return regionConfigArr.find(config => config.regionId === defaultRegion)!;
  }
};
export const getRegionConfig = (regionId: string, regionConfigArr: IRegionConfigItem[]): IRegionConfigItem => {
  const regionConfig = regionConfigArr.find(config => config.regionId === regionId && config.enabled);
  if (regionConfig) {
    return regionConfig;
  } else {
    return regionConfigArr.find(config => config.regionId === defaultRegion)!;
  }
};

export const getRegionConfigByName = (
  regionName: string,
  regionConfigArr: IRegionConfigItem[],
): IRegionConfigItem | undefined => {
  const regionConfig = regionConfigArr.find(config => config.regionName === regionName && config.enabled);
  if (regionConfig) {
    return regionConfig;
  }
  return undefined;
};
export const getRegionNamesAsCSV = (regionConfigArr: IRegionConfigItem[]): string => {
  return regionConfigArr
    .filter(config => config.enabled === true)
    .map(config => config.regionName)
    .join(', ');
};
