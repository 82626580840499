import {
  getScreenSelector,
  IStyle,
  mergeStyleSets,
  ScreenWidthMaxLarge,
  ScreenWidthMaxMedium,
  ScreenWidthMaxSmall,
  ScreenWidthMaxXLarge,
  ScreenWidthMaxXXLarge,
  ScreenWidthMinLarge,
  ScreenWidthMinMedium,
  ScreenWidthMinXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMinXXXLarge,
} from '@fluentui/react';
import {dataGrid} from '../../../../configs';
import {SParkTheme} from '../../../../theme';

export const carpoolStyle = mergeStyleSets({
  msCarpoolMemberStyle: {
    display: 'grid',
    marginLeft: '1%',
    marginRight: '8%',
    marginBottom: '3%',
    gap: '2%',
    selectors: {
      [getScreenSelector(50, ScreenWidthMaxSmall)]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
  } as IStyle,
  scoopMemberStyle: {
    display: 'grid',
    marginLeft: '1%',
    marginRight: '5%',
    gridTemplateColumns: '1fr 2fr',
    selectors: {
      [getScreenSelector(50, ScreenWidthMaxSmall)]: {
        gridTemplateColumns: '1fr 2fr',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        gridTemplateColumns: '1fr 2fr',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        gridTemplateColumns: '1fr 2fr',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
  } as IStyle,
  deleteIconStyle: {
    marginTop: 30,
    color: SParkTheme.palette?.red,
    displayName: dataGrid.gridColumnActionConst.deleteAction.visibleActions.iconDisplayName,
    hover: dataGrid.gridColumnActionConst.deleteAction.visibleActions.hover,
  } as IStyle,
});
