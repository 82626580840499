/* istanbul ignore file */
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {CoherenceDataGrid, CoherenceDataGridColumn} from '@coherence-design-system/controls';
import {Selection, Dialog, DialogFooter, SpinnerSize, PrimaryButton, DefaultButton} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {useId} from '@fluentui/react-hooks';
import dayjs from 'dayjs';
import {IUserDetails, IDataProps} from './DataGrid.types';
import {dataGrid, emptyString, TelemetryConstants} from '../../../configs';
import {DataGridStyles, gridContentStyles, gridDialogStyles, screenReaderOnly} from '.';
import {SParkTheme} from '../../../theme';
import {SpinLoader, RecordNotFound} from '../../common';
import {CarpoolRegistered} from '../Carpool';
import {DataGridUsageEvents} from '../../../configs/usageEvents';
import {emailReceiverAlias, createCCForCarpoolMail} from '../../../utils';
import {
  IUser,
  RootState,
  useGetVehicleDetailsQuery,
  useDeleteVehicleDetailsMutation,
  msDomain,
  deleteCarpoolMailBody,
  deleteCarpoolMailSubject,
  mailTypeCarpool,
  setInfoBadgeState,
  deleteVehicleRegistration,
  setVehicleRegistration,
  updateVehicleRegistration,
  IRegisteredVehicleDataItem,
  useDeleteCarpoolMutation,
  useGetCarpoolQuery,
  usePostEmailMutation,
  getTelemetryClient,
  queryStatus,
  vehicleRegistrationInitialState,
  setRegisterFormState,
  setVehicleDetails,
  setVehicleRegisterError,
  resetCarpool,
  carpoolInitialState,
  IConfigData,
} from '@microsoft/smart-parking-coreui';
import Config, {ConfigKey, infoBadgeDisplayMessage, spinnerMessage} from '../../../configs/constants/uiConfig';

export const DataGrid: React.FC<IDataProps> = ({onEditClick, fromAdmin, gridProcessingProps}: IDataProps) => {
  const [rowCount, setRowCount] = useState(0);
  const dispatch = useDispatch();
  const [registrationId, setRegistrationId] = useState(emptyString);
  const [hideDialog, SetHideDialog] = useState(true);
  const labelId: string = useId('deleteLabel');
  const user: IUser = useSelector((state: RootState) => state.user);
  const userAlias = user.userAlias;
  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const regionConfig = useSelector((state: RootState) => state.regionConfig);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const {
    isFetching: userVehicleDetailsFetching,
    isLoading: userVehicleDetailsLoading,
    isSuccess: userVehicleDetailsSuccess,
    isError: userVehicleDetailsError,
    refetch: userVehicleDetailsRefetch,
    data: userVehicleDetails = [],
    status: userVehicleDetailsRequestStatus,
  } = useGetVehicleDetailsQuery(
    {
      apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
      apiRegistrationUrlSubString: configData[ConfigKey.VehicleRegistrationUrl] as string,
      regionId: regionConfig.regionId,
      requestParams: userAlias,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: userAlias === undefined || userAlias === '' || fromAdmin === true,
    },
  );
  const vehicleRegistration = useSelector((state: RootState) => state.vehicleRegistration);

  const [deleteVehicleByUser] = useDeleteVehicleDetailsMutation();
  const [deleteCarpoolByUser] = useDeleteCarpoolMutation();
  const {data: carpoolApiDataByUser} = useGetCarpoolQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionConfig.regionId,
      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || fromAdmin === true,
    },
  );
  const [sendCarpoolEmail] = usePostEmailMutation();
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const {
    isFetching,
    isLoading,
    isSuccess,
    isError,
    refetch,
    data,
    status,
    deleteVehicle,
    deleteCarpool,
    carpoolApiData,
  } = fromAdmin
    ? gridProcessingProps
    : {
        isFetching: userVehicleDetailsFetching,
        isLoading: userVehicleDetailsLoading,
        isSuccess: userVehicleDetailsSuccess,
        isError: userVehicleDetailsError,
        refetch: userVehicleDetailsRefetch,
        data: userVehicleDetails,
        status: userVehicleDetailsRequestStatus,
        deleteVehicle: deleteVehicleByUser,
        deleteCarpool: deleteCarpoolByUser,
        carpoolApiData: carpoolApiDataByUser,
      };
  const logProperties = useMemo(
    () => ({
      registrations: vehicleRegistration,
      ...user,
      isAdmin: fromAdmin,
      url: window.location.href,
    }),
    [vehicleRegistration, user, fromAdmin],
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(DataGridUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(DataGridUsageEvents.root, undefined, logProperties);
    };
  }, [logProperties, telemetryClient]);

  const sendCarpoolDeleteEmail = async () => {
    const sendToAlias = emailReceiverAlias(vehicleRegistration, registrationId);
    try {
      await sendCarpoolEmail({
        emailApiBaseUrl: configData[ConfigKey.CarpoolEmailUrl] as string,
        regionId: regionConfig.regionId,
        requestData: {
          to: sendToAlias + msDomain,
          emailBody: deleteCarpoolMailBody(),
          subject: deleteCarpoolMailSubject,
          cc: createCCForCarpoolMail(carpoolApiData),
          mailType: mailTypeCarpool,
        },
      }).unwrap();
      telemetryClient.trackEvent(DataGridUsageEvents.SendCarpoolDeleteEmailSuccess, logProperties);
    } catch (error) {
      telemetryClient.trackEvent(DataGridUsageEvents.SendCarpoolDeleteEmailFailure, logProperties);
      telemetryClient.trackException({exception: error as Error}, logProperties);
    }
  };

  const onClickOk = async () => {
    telemetryClient.startTrackEvent(DataGridUsageEvents.DeleteRegistrationOK);
    setDeleteInProgress(true);
    try {
      await deleteVehicle(
        fromAdmin
          ? {
              apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
              regionId: regionConfig.regionId,
              apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
              requestParams: registrationId,
            }
          : {
              apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
              regionId: regionConfig.regionId,
              apiRegistrationUrlSubString: configData[ConfigKey.VehicleRegistrationUrl] as string,
              requestParams: registrationId,
            },
      ).unwrap();
      telemetryClient.startTrackEvent(DataGridUsageEvents.DeleteRegistrationSuccess);
      await refetch();
      dispatch(
        setInfoBadgeState({
          response: true,
          responseStatus: 'success',
          message: infoBadgeDisplayMessage.delete.success,
          closeStatusModal: false,
        }),
      );
      if (isSuccess && vehicleRegistration) {
        dispatch(deleteVehicleRegistration(registrationId));
        dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
        dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialvehicleRegistrationState[0]));
        dispatch(
          setVehicleRegisterError({
            isFormSubmit: false,
            isVehicleTypeError: false,
            isMakeError: false,
            isModelError: false,
            isColorError: false,
            isYearError: false,
            isLicensePlateError: false,
            isStateError: false,
            isRegistrationTypeError: false,
            isParkingLocationError: false,
            isPolicyCheckedError: false,
            closeModal: false,
            isFirstNameError: false,
            isLastNameError: false,
            isChaufferContactNumberError: false,
            isChaufferDrivingLicenseError: false,
            isChaufferNameError: false,
            isCountryError: false,
            isContactNumberError: false,
            isFuelTypeError: false,
            isExpiryDateError: false,
          }),
        );
        dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
      }
      telemetryClient.stopTrackEvent(DataGridUsageEvents.DeleteRegistrationSuccess, logProperties);
      if (
        vehicleRegistration.find((item: {registrationId: string}) => item.registrationId === registrationId)
          ?.carpoolStatus
      ) {
        try {
          await deleteCarpool(
            fromAdmin
              ? {
                  carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                  carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl],
                  regionId: regionConfig.regionId,
                  registrationId: registrationId,
                }
              : {
                  carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                  carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl],
                  regionId: regionConfig.regionId,
                  registrationId: registrationId,
                },
          ).unwrap();
          telemetryClient.trackEvent(DataGridUsageEvents.DeleteCarpoolRegistrationSuccess, logProperties);
          await sendCarpoolDeleteEmail();
        } catch (ex) {
          telemetryClient.trackException({exception: ex as Error}, logProperties);
          telemetryClient.trackEvent(DataGridUsageEvents.DeleteCarpoolRegistrationFailure, logProperties);
        }
      }
    } catch (ex) {
      dispatch(
        setInfoBadgeState({
          response: true,
          responseStatus: 'error',
          message: infoBadgeDisplayMessage.delete.error,
          closeStatusModal: false,
        }),
      );
      telemetryClient.trackException({exception: ex as Error}, logProperties);
      telemetryClient.trackEvent(DataGridUsageEvents.DeleteRegistrationFailure, logProperties);
    }
    setDeleteInProgress(false);
    SetHideDialog(true);
    setRegistrationId(emptyString);
  };

  const onClickCancel = () => {
    telemetryClient.trackEvent(DataGridUsageEvents.DeleteRegistrationCancel, logProperties);
    SetHideDialog(true);
  };
  const onClickDelete = (e: any) => {
    telemetryClient.trackEvent(DataGridUsageEvents.DeleteRegistrationOK, logProperties);
    setRegistrationId(e.id);
    SetHideDialog(false);
  };

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: gridDialogStyles,
      dragOptions: dataGrid.gridDragOptions,
    }),
    [labelId],
  );
  const columns: CoherenceDataGridColumn<IUserDetails>[] = [
    ...(regionConfig.showV2UI ? dataGrid.gridColumnV2 : dataGrid.gridColumn),
    {
      key: dataGrid.gridColumnActionConst.editAction.key,
      name: dataGrid.gridColumnActionConst.editAction.name,
      type: 'actions',
      menuActions: [],
      isResizable: true,
      minColumnWidth: 35,
      visibleActions: [
        {
          role: 'button',
          tabIndex: 0,
          onClick: item => onEditClick(item),
          key: dataGrid.gridColumnActionConst.editAction.visibleActions.key,
          text: dataGrid.gridColumnActionConst.editAction.visibleActions.text,
          title: dataGrid.gridColumnActionConst.editAction.visibleActions.title,
          iconProps: {
            iconName: dataGrid.gridColumnActionConst.editAction.visibleActions.iconName,
            styles: {
              root: {
                color: SParkTheme.palette?.blueDark,
                displayName: dataGrid.gridColumnActionConst.editAction.visibleActions.iconDisplayName,
              },
            },
          },
        },
      ],
    },
    {
      key: dataGrid.gridColumnActionConst.deleteAction.key,
      name: dataGrid.gridColumnActionConst.deleteAction.name,
      type: 'actions',
      menuActions: [],
      isResizable: true,
      minColumnWidth: 50,
      visibleActions: [
        {
          role: 'button',
          tabIndex: 0,
          onClick: item => onClickDelete(item),
          key: dataGrid.gridColumnActionConst.deleteAction.visibleActions.key,
          text: dataGrid.gridColumnActionConst.deleteAction.visibleActions.text,
          title: dataGrid.gridColumnActionConst.deleteAction.visibleActions.title,
          iconProps: {
            iconName: dataGrid.gridColumnActionConst.deleteAction.visibleActions.iconName,
            styles: {
              root: {
                color: SParkTheme.palette?.red,
                displayName: dataGrid.gridColumnActionConst.deleteAction.visibleActions.iconDisplayName,
                hover: dataGrid.gridColumnActionConst.deleteAction.visibleActions.hover,
              },
            },
          },
        },
      ],
    },
  ];

  useEffect(() => {
    data && isSuccess && status === queryStatus.Fulfilled && dispatch(setVehicleRegistration(data));
  }, [data, dispatch, isSuccess, status]);

  const updateCarpoolStatus = useCallback(
    (carpoolStatus: boolean, registrationId: string) => {
      dispatch(
        updateVehicleRegistration({
          ...vehicleRegistrationInitialState.initialvehicleRegistrationState[0],
          ...data,
          carpoolStatus,
          registrationId,
        }),
      );
    },
    [data, dispatch],
  );

  const dataGridDataResponse: IUserDetails[] = React.useMemo(() => {
    if (isSuccess && data && status === queryStatus.Fulfilled && vehicleRegistration) {
      return vehicleRegistration.map((data: IRegisteredVehicleDataItem) => {
        return {
          key: data.registrationId,
          id: data.registrationId,
          owner: data.ownerFirstName + ' ' + data.ownerLastName,
          makeAndModel: data.make + ' / ' + (data.model === emptyString ? '--' : data.model),
          makeYear: data.year,
          vehicleColor: data.color,
          vehicleLicense: data.licensePlate,
          vehicleState: data.state,
          msPermit: data.permitNumber,
          issuedate: dayjs(data.registrationDate).format('MM/DD/YYYY'),
          regType: data.registrationType,
          carpool: data?.carpoolStatus ? 'Yes' : 'No',
        };
      });
    }
    return [] as IUserDetails[];
  }, [data, isSuccess, status, vehicleRegistration]);

  const gridData: IUserDetails[] = dataGridDataResponse;
  const [selectedGridData, setSelectedGridData] = React.useState<Object | undefined>(undefined);
  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedGridData(selection.getSelection());
    },
  });

  useEffect(() => {
    setRowCount(gridData.length);
  }, [selectedGridData, gridData]);

  return (
    <>
      {isLoading || isFetching ? (
        <SpinLoader
          size={SpinnerSize.xSmall}
          className={DataGridStyles.spinnerStyle}
          label={spinnerMessage.dataGrid.isLoading}></SpinLoader>
      ) : isSuccess && gridData.length === 0 ? (
        <RecordNotFound message={spinnerMessage.dataGrid.noResultsFound} isError={false}></RecordNotFound>
      ) : isError ? (
        <RecordNotFound message={spinnerMessage.dataGrid.isError} isError={true}></RecordNotFound>
      ) : (
        <div className={DataGridStyles.rootDiv}>
          <div className={DataGridStyles.divPadding}>
            <label
              className={
                screenReaderOnly
              }>{`${gridData.length} records found with a table of ${columns.length} columns. Table Columns are sortable`}</label>
            {rowCount > 0 && isSuccess && (
              <>
                <CoherenceDataGrid
                  listConfig={columns}
                  data={gridData}
                  isSortable={true}
                  initialSortedColumn={gridData[0].issuedate}
                  compactMode={'compact'}
                />
                {vehicleRegistration ? (
                  vehicleRegistration.map((item: {registrationId: string}) => (
                    <CarpoolRegistered
                      registrationId={item.registrationId}
                      updateCarpoolStatus={updateCarpoolStatus}
                      key={item.registrationId}
                      fromAdmin={fromAdmin || false}
                    />
                  ))
                ) : (
                  <></>
                )}
              </>
            )}
            <label id={labelId} className={screenReaderOnly}>
              {`Delete Registration confirmation dialog`}
            </label>
            <Dialog
              hidden={hideDialog}
              onDismiss={onClickCancel}
              dialogContentProps={{
                ...dataGrid.deleteRegistrationDialogProps,
                styles: gridContentStyles,
              }}
              modalProps={modalProps}>
              {deleteInProgress && (
                <SpinLoader
                  size={SpinnerSize.xSmall}
                  className={DataGridStyles.spinnerStyle}
                  label={spinnerMessage.delete.loading}></SpinLoader>
              )}
              <DialogFooter>
                <>
                  <PrimaryButton
                    onClick={onClickOk}
                    disabled={deleteInProgress}
                    text={dataGrid.buttonText.ok}
                    className={DataGridStyles.okButtonStyle}
                  />
                  <DefaultButton
                    onClick={onClickCancel}
                    disabled={deleteInProgress}
                    text={dataGrid.buttonText.cancel}
                  />
                </>
              </DialogFooter>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
};
