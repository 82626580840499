import React from 'react';
import {MsalProvider} from '@azure/msal-react';
import {Provider} from 'react-redux';
import {loadTheme, mergeStyles, ThemeProvider} from '@fluentui/react';
import {App} from './app/App';
import MsalAuthProvider from './authentication/MsalAuthProvider';
import {authHelper, store} from '@microsoft/smart-parking-coreui';
import Config from './configs/constants/uiConfig';
import {createRoot} from 'react-dom/client';
import {SParkTheme} from './theme';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
loadTheme(SParkTheme);
root.render(
  <React.StrictMode>
    <MsalProvider
      instance={authHelper.getMsalInstance(
        Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
        Config.REACT_APP_MSAL_CLIENT_ID,
      )}>
      <MsalAuthProvider>
        <Provider store={store}>
          <ThemeProvider {...SParkTheme}>
            <App />
          </ThemeProvider>
        </Provider>
      </MsalAuthProvider>
    </MsalProvider>
  </React.StrictMode>,
);
