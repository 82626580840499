import {PrimaryButton, Separator, Stack, Text} from '@fluentui/react';
import {
  emptyString,
  getTelemetryClient,
  IRegisteredVehicleDataItem,
  queryStatus,
  RootState,
  setRegisterFormState,
  setVehicleRegisterError,
  useGetVehicleDetailsByAliasQuery,
  useGetVehicleDetailsByLicenseQuery,
  useGetVehicleDetailsByPermitQuery,
  useDeleteVehicleDetailsByAdminMutation,
  useUpdateVehicleDetailsByAdminMutation,
  useGetCarpoolAdminQuery,
  useDeleteCarpoolAdminMutation,
  useUpdateCarpoolAdminMutation,
  usePostCarpoolAdminMutation,
  setVehicleDetails,
  vehicleRegistrationInitialState,
  resetCarpool,
  carpoolInitialState,
  IConfigData,
} from '@microsoft/smart-parking-coreui';
import {useMemo, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  InputField,
  FullWidthContainer,
  GridStyle,
  ownerStackTokens,
  DataGrid,
  IUserDetails,
  RegistrationForm,
  Help,
} from '../../components';
import {
  adminPanelConst,
  AdminScreenInputParamConst,
  EditRegistrationUsageEvents,
  TelemetryConstants,
} from '../../configs';
import {EditVehicleDetails} from '../EditVehicleDetails';
import {mainPanelStyles} from '../MainPanel';
import {adminSearchPanelStyle, buttonStyle, inputStyles, searchBarStyle} from './AdminScreen.styles';
import {IAdminSearchParamInputState, requestTriggerType} from './AdminScreen.types';
import Config, {
  ConfigKey,
  EditVehicleDetailsLabels,
  adminSearchFieldErrorMessages,
  initialAdminSearchParamsInputState,
} from '../../configs/constants/uiConfig';
import {AdminScreenUsageEvents} from '../../configs/usageEvents/pages/AdminScreenUsageEvents';
import {noSpecialCharacterAndSpaces, onlyHasAllowedChars} from '../../utils';

export const AdminScreen = () => {
  const {root, stackItemStyles, registerHeadingFontStyles} = mainPanelStyles;
  const [inputText, setInputText] = useState<IAdminSearchParamInputState>(initialAdminSearchParamsInputState);
  const [aliasParam, setAliasParam] = useState('');
  const [licenseParam, setLicenseParam] = useState('');
  const [permitParam, setPermitParam] = useState('');
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [editItemId, setEditItemId] = useState('');
  const dispatch = useDispatch();
  const regionConfig = useSelector((state: RootState) => state.regionConfig);
  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  const [requestTrigger, setRequestTrigger] = useState<requestTriggerType>(requestTriggerType.Empty);
  const {
    isSuccess: isRegistrationSearchByAliasSuccess,
    isError: isRegistrationSearchByAliasError,
    isLoading: isRegistrationSearchByAliasLoading,
    isFetching: isRegistrationSearchByAliasFetching,
    data: registrationSearchByAliasData = [],
    refetch: registrationSearchByAliasRefetch,
    status: registrationSearchByAliasRequestStatus,
  } = useGetVehicleDetailsByAliasQuery(
    {
      apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
      apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
      regionId: regionConfig.regionId,
      requestParams: aliasParam,
    },
    {
      skip: aliasParam === '' || requestTrigger !== requestTriggerType.Alias,
    },
  );
  const {
    isSuccess: isRegistrationSearchByLicenseSuccess,
    isError: isRegistrationSearchByLicenseError,
    isLoading: isRegistrationSearchByLicenseLoading,
    isFetching: isRegistrationSearchByLicenseFetching,
    data: registrationSearchByLicenseData = [],
    refetch: registrationSearchByLicenseRefetch,
    status: registrationSearchByLicenseRequestStatus,
  } = useGetVehicleDetailsByLicenseQuery(
    {
      apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
      apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
      regionId: regionConfig.regionId,
      requestParams: licenseParam,
    },
    {
      skip: licenseParam === '' || requestTrigger !== requestTriggerType.License,
    },
  );
  const {
    isSuccess: isRegistrationSearchByPermitSuccess,
    isError: isRegistrationSearchByPermitError,
    isLoading: isRegistrationSearchByPermitLoading,
    isFetching: isRegistrationSearchByPermitFetching,
    data: registrationSearchByPermitData = [],
    refetch: registrationSearchByPermitRefetch,
    status: registrationSearchByPermitRequestStatus,
  } = useGetVehicleDetailsByPermitQuery(
    {
      apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
      apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
      regionId: regionConfig.regionId,
      requestParams: permitParam,
    },
    {
      skip: permitParam === '' || requestTrigger !== requestTriggerType.Permit,
    },
  );
  const isFormEdit = useSelector((state: RootState) => state.formState.isFormEdit);
  const [deleteVehicleByAdmin] = useDeleteVehicleDetailsByAdminMutation();
  const [deleteCarpoolByAdmin] = useDeleteCarpoolAdminMutation();
  const [updatePostByAdmin] = useUpdateVehicleDetailsByAdminMutation();
  const [updateCarpoolByAdmin] = useUpdateCarpoolAdminMutation();
  const [createCarpoolPostByAdmin] = usePostCarpoolAdminMutation();
  const {data: carpoolApiDataByAdmin} = useGetCarpoolAdminQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
      regionId: regionConfig.regionId,
      registrationId: editItemId,
    },
    {
      skip: editItemId === undefined || editItemId === emptyString,
    },
  );
  const logProperties = useMemo(
    () => ({
      registrationSearchByAliasData: {...registrationSearchByAliasData},
      registrationSearchByLicenseData: {...registrationSearchByLicenseData},
      registrationSearchByPermitData: {...registrationSearchByPermitData},
      carpoolAdminData: {...carpoolApiDataByAdmin},
      requestTrigger,
      url: window.location.href,
    }),
    [
      carpoolApiDataByAdmin,
      registrationSearchByAliasData,
      registrationSearchByLicenseData,
      registrationSearchByPermitData,
      requestTrigger,
    ],
  );
  const handleInputChange = (event: any, name: string) => {
    event.preventDefault();
    !(
      (name === AdminScreenInputParamConst.aliasParamName && !onlyHasAllowedChars(event.target.value)) ||
      (name === AdminScreenInputParamConst.permitParamName && !noSpecialCharacterAndSpaces(event.target.value)) ||
      (name === AdminScreenInputParamConst.licenseParamName && !noSpecialCharacterAndSpaces(event.target.value))
    ) && setInputText({...inputText, [name]: event.target.value});
  };

  const resetAllStates = () => {
    dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
    dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialvehicleRegistrationState[0]));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
        isChaufferContactNumberError: false,
        isChaufferDrivingLicenseError: false,
        isChaufferNameError: false,
        isCountryError: false,
        isContactNumberError: false,
        isFuelTypeError: false,
        isExpiryDateError: false,
      }),
    );
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
  };

  const handleSearchByAliasClick = (event: any) => {
    telemetryClient.startTrackEvent(AdminScreenUsageEvents.searchByAlias);
    event.preventDefault();
    resetAllStates();
    setAliasParam(inputText.aliasParamInput);
    setRequestTrigger(requestTriggerType.Alias);
    gridProcessingProps().refetch();
    setShowDataGrid(true);
    setInputText({...inputText, licenseParamInput: emptyString, permitParamInput: emptyString});
    telemetryClient.stopTrackEvent(AdminScreenUsageEvents.searchByAlias, registrationSearchByAliasData);
  };
  const handleSearchByLicenseClick = (event: any) => {
    telemetryClient.startTrackEvent(AdminScreenUsageEvents.searchByLicense);
    event.preventDefault();
    resetAllStates();
    setLicenseParam(inputText.licenseParamInput);
    setRequestTrigger(requestTriggerType.License);
    gridProcessingProps().refetch();
    setShowDataGrid(true);
    setInputText({...inputText, aliasParamInput: emptyString, permitParamInput: emptyString});
    telemetryClient.stopTrackEvent(AdminScreenUsageEvents.searchByAlias, registrationSearchByAliasData);
  };
  const handleSearchByPermitClick = (event: any) => {
    telemetryClient.startTrackEvent(AdminScreenUsageEvents.searchByPermit);
    event.preventDefault();
    resetAllStates();
    setPermitParam(inputText.permitParamInput);
    setRequestTrigger(requestTriggerType.Permit);
    gridProcessingProps().refetch();
    setShowDataGrid(true);
    setInputText({...inputText, aliasParamInput: emptyString, licenseParamInput: emptyString});
    telemetryClient.stopTrackEvent(AdminScreenUsageEvents.searchByAlias, registrationSearchByAliasData);
  };
  const validateError = (textBoxFieldName: string, textBoxFieldValue: string) => {
    switch (textBoxFieldName) {
      case AdminScreenInputParamConst.aliasParamName:
        return requestTrigger === requestTriggerType.Alias && textBoxFieldValue === emptyString
          ? adminSearchFieldErrorMessages.aliasParamErrorMessage
          : emptyString;
      case AdminScreenInputParamConst.licenseParamName:
        return requestTrigger === requestTriggerType.License && textBoxFieldValue === emptyString
          ? adminSearchFieldErrorMessages.licenseParamErrorMessage
          : emptyString;
      case AdminScreenInputParamConst.permitParamName:
        return requestTrigger === requestTriggerType.Permit && textBoxFieldValue === emptyString
          ? adminSearchFieldErrorMessages.permitParamErrorMessage
          : emptyString;
    }
  };
  const gridProcessingProps = () => {
    switch (requestTrigger) {
      case requestTriggerType.Alias:
        return {
          isSuccess: isRegistrationSearchByAliasSuccess,
          isError: isRegistrationSearchByAliasError,
          isLoading: isRegistrationSearchByAliasLoading,
          isFetching: isRegistrationSearchByAliasFetching,
          data: registrationSearchByAliasData,
          refetch: registrationSearchByAliasRefetch,
          status: registrationSearchByAliasRequestStatus,
        };
      case requestTriggerType.License:
        return {
          isSuccess: isRegistrationSearchByLicenseSuccess,
          isError: isRegistrationSearchByLicenseError,
          isLoading: isRegistrationSearchByLicenseLoading,
          isFetching: isRegistrationSearchByLicenseFetching,
          data: registrationSearchByLicenseData,
          refetch: registrationSearchByLicenseRefetch,
          status: registrationSearchByLicenseRequestStatus,
        };
      case requestTriggerType.Permit:
        return {
          isSuccess: isRegistrationSearchByPermitSuccess,
          isError: isRegistrationSearchByPermitError,
          isLoading: isRegistrationSearchByPermitLoading,
          isFetching: isRegistrationSearchByPermitFetching,
          data: registrationSearchByPermitData,
          refetch: registrationSearchByPermitRefetch,
          status: registrationSearchByPermitRequestStatus,
        };
      default:
        return {
          isSuccess: false,
          isError: false,
          isLoading: false,
          isFetching: false,
          data: {} as IRegisteredVehicleDataItem[],
          refetch: () => {},
          status: queryStatus.Uninitialized,
        };
    }
  };

  const allProcessingProps = {
    ...gridProcessingProps(),
    deleteVehicle: deleteVehicleByAdmin,
    deleteCarpool: deleteCarpoolByAdmin,
    updatePost: updatePostByAdmin,
    carpoolApiData: carpoolApiDataByAdmin,
    createCarpoolPost: createCarpoolPostByAdmin,
    updateCarpool: updateCarpoolByAdmin,
  };
  const onEditClick = (item: IUserDetails) => {
    telemetryClient.startTrackEvent(EditRegistrationUsageEvents.EditRegistration);
    setEditItemId(item.id);
    dispatch(setRegisterFormState({isFormEdit: true, isAnyFieldEdited: false}));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
        isChaufferContactNumberError: false,
        isChaufferDrivingLicenseError: false,
        isChaufferNameError: false,
        isCountryError: false,
        isContactNumberError: false,
        isFuelTypeError: false,
        isExpiryDateError: false,
      }),
    );
    window.scrollTo({left: 0, top: 0.7 * document.body.scrollHeight, behavior: 'smooth'});
    telemetryClient.stopTrackEvent(EditRegistrationUsageEvents.EditRegistration, logProperties);
  };

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(AdminScreenUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(AdminScreenUsageEvents.root, undefined, logProperties);
    };
  }, [telemetryClient, logProperties]);

  return (
    <Stack className={root}>
      <GridStyle gridGap={6}>
        <FullWidthContainer>
          <Stack.Item align="auto" className={stackItemStyles}>
            <Text className={registerHeadingFontStyles}> {adminPanelConst.header.headerText}</Text>
            <Stack horizontal className={adminSearchPanelStyle} tokens={ownerStackTokens}>
              <div className={searchBarStyle}>
                <InputField
                  inputType={'text'}
                  styles={inputStyles}
                  name={AdminScreenInputParamConst.aliasParamName}
                  placeholder={adminPanelConst.fieldPlaceholders.searchByAlias}
                  onChange={event => handleInputChange(event, AdminScreenInputParamConst.aliasParamName)}
                  value={inputText.aliasParamInput}
                  label={adminPanelConst.fieldLabels.searchByAlias}
                  validateOnLoad={false}
                  required={false}
                  errorMessage={validateError(AdminScreenInputParamConst.aliasParamName, inputText.aliasParamInput)}
                />
                <PrimaryButton
                  style={buttonStyle}
                  className="SearchByAlias"
                  title="Search By Alias"
                  text="Search"
                  onClick={handleSearchByAliasClick}
                />
              </div>
              <div className={searchBarStyle}>
                <InputField
                  inputType={'text'}
                  styles={inputStyles}
                  name={AdminScreenInputParamConst.licenseParamName}
                  maxLength={10}
                  placeholder={adminPanelConst.fieldPlaceholders.searchByLicense}
                  onChange={event => handleInputChange(event, AdminScreenInputParamConst.licenseParamName)}
                  value={inputText.licenseParamInput}
                  IconLabel={{
                    title: adminPanelConst.fieldLabels.searchByLicense.title,
                    icon: adminPanelConst.fieldLabels.searchByLicense.icon,
                    label: adminPanelConst.fieldLabels.searchByLicense.label,
                  }}
                  validateOnLoad={false}
                  required={false}
                  errorMessage={validateError(AdminScreenInputParamConst.licenseParamName, inputText.licenseParamInput)}
                />
                <PrimaryButton
                  style={buttonStyle}
                  className="SearchByLicense"
                  title="Search By License"
                  text="Search"
                  onClick={handleSearchByLicenseClick}
                />
              </div>
              <div className={searchBarStyle}>
                <InputField
                  inputType={'text'}
                  styles={inputStyles}
                  name={AdminScreenInputParamConst.permitParamName}
                  maxLength={10}
                  placeholder={adminPanelConst.fieldPlaceholders.searchByPermit}
                  onChange={event => handleInputChange(event, AdminScreenInputParamConst.permitParamName)}
                  value={inputText.permitParamInput}
                  label={adminPanelConst.fieldLabels.searchByPermit}
                  validateOnLoad={false}
                  required={false}
                  errorMessage={validateError(AdminScreenInputParamConst.permitParamName, inputText.permitParamInput)}
                />
                <PrimaryButton
                  style={buttonStyle}
                  className="SearchByPermit"
                  title="Search By Permit"
                  text="Search"
                  onClick={handleSearchByPermitClick}
                />
              </div>
            </Stack>
            <div aria-live="polite">
              {showDataGrid && (
                <DataGrid onEditClick={onEditClick} gridProcessingProps={allProcessingProps} fromAdmin={true} />
              )}
            </div>
            {isFormEdit && (
              <>
                <Text className={registerHeadingFontStyles}>{EditVehicleDetailsLabels.headerText}</Text>
                <Separator />
                <RegistrationForm isFormAdmin={true} />
                <EditVehicleDetails
                  registrationId={editItemId}
                  refetch={gridProcessingProps().refetch}
                  fromAdmin={true}
                  editRegistrationProcessingProps={allProcessingProps}
                />
                <Help />
              </>
            )}
          </Stack.Item>
        </FullWidthContainer>
      </GridStyle>
    </Stack>
  );
};
