import {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {authHelper, ICarpoolData, IRegisterVehicleDataItem, RootState} from '@microsoft/smart-parking-coreui';
import {CarpoolError} from '../../components';
import {MemberType} from '../../configs';
import {isAliasFormatValid} from '../../utils';

const useCarpoolValidation = () => {
  const [carpoolErrors, setCarpoolErrors] = useState<CarpoolError[]>([]);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const vehicleDetails: IRegisterVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const carpoolChecked = vehicleDetails.isCarpoolChecked;

  const previousAliases = useRef<string[]>([]);

  useEffect(() => {
    const validateCarpool = async () => {
      if (!carpoolChecked || !carpoolDetails) {
        setCarpoolErrors([]);
        return;
      }

      const errors = await Promise.all(
        carpoolDetails.carpoolMembers.map(async (value, index, array) => {
          let valueError = {} as CarpoolError;

          const aliasList = array
            .slice(0, index)
            .filter(member => member.memberType === MemberType.MsEmployee)
            .map(member => member.memberAlias?.toLowerCase());

          if (!value.memberType) {
            valueError['memberType'] = true;
          }

          if (value.memberType === MemberType.MsEmployee) {
            const memberAlias = value.memberAlias?.toLowerCase();
            if (!value.memberAlias || !isAliasFormatValid(value.memberAlias)) {
              valueError['memberAlias'] = true;
            } else if (memberAlias && aliasList.includes(memberAlias)) {
              valueError['aliasConflict'] = true;
            } else if (memberAlias) {
              // Check if alias has changed from the previous value
              const previousAlias = previousAliases.current[index];

              // Only make the alias validation call if the alias has changed
              if (previousAlias !== memberAlias) {
                const tokenResult = await authHelper.getGraphApiAccessToken();
                const isAliasValid = await authHelper.getAliasValidation(tokenResult.accessToken, memberAlias);
                if (!isAliasValid) {
                  valueError['memberAlias'] = true;
                } else {
                  previousAliases.current[index] = memberAlias;
                }
              }
            }
          }

          if (!value.originCity && value.memberType !== MemberType.ScoopEmployee) {
            valueError['originCity'] = true;
          }

          return {
            id: index,
            ...valueError,
          };
        }),
      );

      setCarpoolErrors(errors);
    };

    validateCarpool();
  }, [carpoolChecked, carpoolDetails]);

  return {carpoolErrors};
};

export default useCarpoolValidation;
