export const TelemetryConstants = {
  logMetrics: {
    StatusCode: 'StatusCode',
  },
  status: {
    success: 'Success',
    failed: 'Failed',
    completed: 'Completed',
    started: 'Started',
  },
  metadata: {
    AppName: 'SmartParkingWeb',
  },
};
