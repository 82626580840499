import React from 'react';
import {Stack, Link} from '@fluentui/react';
import {
  adminLinkRouteStyles,
  INavigationPanelProps,
  userVehicleLinkRouteStyles,
  navigationPanelStyles,
  reportLinkRouteStyles,
} from '.';
import {FullWidthContainer, GridStyle} from '../../components';
import {NavigationPanelConst} from '../../configs';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setCarpoolError,
  setInfoBadgeState,
  setRegisterFormState,
  setVehicleDetails,
  vehicleRegistrationInitialState,
  setVehicleRegisterError,
  carpoolInitialState,
  resetCarpool,
  IUser,
  RootState,
} from '@microsoft/smart-parking-coreui';

export const NavigationPanel: React.FunctionComponent<INavigationPanelProps> = ({
  isAdminEnabled,
  areReportsEnabled,
}) => {
  const {root, stackItemStyles, mainHeadingStyles, mainHeadingFontStyles, divStylesLink, userDetailsStyles} =
    navigationPanelStyles;
  const user: IUser = useSelector((state: RootState) => state.user);
  const userFirstName = user.firstName;
  const userLastName = user.lastName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const resetState = () => {
    dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
    dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialRegisterVehicleState));
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    dispatch(setInfoBadgeState(vehicleRegistrationInitialState.initialInfoBadgeResponseState));
    dispatch(setCarpoolError({isCarpoolError: false}));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
        isChaufferContactNumberError: false,
        isChaufferDrivingLicenseError: false,
        isChaufferNameError: false,
        isCountryError: false,
        isContactNumberError: false,
        isFuelTypeError: false,
        isExpiryDateError: false,
      }),
    );
  };

  const handleAdminRoute = () => {
    navigate('/admin');
    resetState();
  };

  const handleReportRoute = () => {
    navigate('/reports');
    resetState();
  };

  const handleMyVehicleRoute = () => {
    navigate('/');
    resetState();
  };

  return (
    <Stack className={root}>
      <GridStyle gridGap={6.4}>
        <FullWidthContainer>
          <div className={mainHeadingStyles}>
            <h1 className={mainHeadingFontStyles}>{NavigationPanelConst.header.headertext}</h1>
            <p className={userDetailsStyles}>
              Hi {userFirstName} {userLastName}!
            </p>
          </div>
          {(isAdminEnabled || areReportsEnabled) && (
            <Stack.Item align="auto" className={stackItemStyles}>
              <div className={divStylesLink} role="tablist">
                <Link
                  className={userVehicleLinkRouteStyles(location.pathname)}
                  onClick={handleMyVehicleRoute}
                  aria-label="Navigate to my vehicles"
                  aria-selected={location.pathname === '/'}
                  role="tab">
                  {NavigationPanelConst.routeNames.userRoute}
                </Link>
                {isAdminEnabled && (
                  <Link
                    className={adminLinkRouteStyles(location.pathname)}
                    onClick={handleAdminRoute}
                    aria-label="navigate to admin"
                    aria-selected={location.pathname === '/admin'}
                    role="tab">
                    {NavigationPanelConst.routeNames.adminRoute}
                  </Link>
                )}
                {areReportsEnabled && (
                  <Link
                    className={reportLinkRouteStyles(location.pathname)}
                    onClick={handleReportRoute}
                    aria-label="navigate to reports"
                    aria-selected={location.pathname === '/reports'}
                    role="tab">
                    {NavigationPanelConst.routeNames.reportsRoute}
                  </Link>
                )}
              </div>
            </Stack.Item>
          )}
        </FullWidthContainer>
      </GridStyle>
    </Stack>
  );
};
