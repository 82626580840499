import {Label, MessageBarType, IconButton, Icon} from '@fluentui/react';
import {useEffect} from 'react';
import {CarpoolError, ErrorProps, errorBadgeStyles, iconPropsStyle} from '.';
import {ErrorBadgeUsageEvents, TelemetryConstants} from '../../../configs';
import {MessageBanner, FullWidthContainer, GridStyle} from '../../';
import {getNumberChar} from '../../../utils';
import Config, {carpoolErrorMessage, errorBadgeMessage, errorListheading} from '../../../configs/constants/uiConfig';
import {getTelemetryClient, RootState} from '@microsoft/smart-parking-coreui';
import {useSelector} from 'react-redux';

export const ErrorBadge = ({
  isFirstNameError,
  isLastNameError,
  isVehicleTypeError,
  isMakeError,
  isModelError,
  isColorError,
  isYearError,
  isLicensePlateError,
  isRegistrationTypeError,
  isStateError,
  isParkingLocationError,
  isParkingPolicyCheckedError,
  onDismiss,
  isCarpoolError,
  carpoolErrors,
  carpoolCounterError,
  carpoolPolicyCheckedError,
  isContactNumberError,
  isFuelTypeError,
  isExpiryDateError,
}: ErrorProps): JSX.Element => {
  const {
    messageStyle,
    labelDivStyle,
    labelChildStyle,
    childDiv,
    errorHeadingStyle,
    labelPolicyStyle,
    iconStyle,
    closeImageStyle,
    mainDivStyle,
  } = errorBadgeStyles;
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const regionConfig = useSelector((state: RootState) => state.regionConfig);
  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(ErrorBadgeUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(ErrorBadgeUsageEvents.root);
    };
  }, [telemetryClient]);

  return (
    <div className={mainDivStyle}>
      <GridStyle>
        <FullWidthContainer>
          <MessageBanner
            className={messageStyle}
            messageBarType={MessageBarType.error}
            onDismiss={onDismiss}
            dismissIconProps={iconPropsStyle}
            messageBarIconProps={iconPropsStyle}>
            <div className={childDiv}>
              <Icon iconName="Blocked2Solid" className={iconStyle} />;
              <div className={labelDivStyle}>
                <Label className={errorHeadingStyle}>{errorListheading.errorHeadingText}</Label>
                {isFirstNameError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.FirstNameErrorMessage}</Label>
                )}
                {isLastNameError && <Label className={labelChildStyle}>{errorBadgeMessage.LastNameErrorMessage}</Label>}
                {isVehicleTypeError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.VehicleTypeErrorMessage}</Label>
                )}
                {isMakeError && <Label className={labelChildStyle}>{errorBadgeMessage.MakeErrorMessage}</Label>}
                {isModelError && <Label className={labelChildStyle}>{errorBadgeMessage.ModelErrorMessage}</Label>}
                {isYearError && <Label className={labelChildStyle}>{errorBadgeMessage.YearErrorMessage}</Label>}
                {isColorError && (
                  <Label className={labelChildStyle}>
                    {regionConfig.showV2UI
                      ? errorBadgeMessage.ColorInputErrorMessage
                      : errorBadgeMessage.ColorErrorMessage}
                  </Label>
                )}
                {isLicensePlateError && (
                  <Label className={labelChildStyle}>
                    {regionConfig.showV2UI
                      ? errorBadgeMessage.RegistrationNoErrorMessage
                      : errorBadgeMessage.LicensePlateErrorMessage}
                  </Label>
                )}
                {isStateError && <Label className={labelChildStyle}>{errorBadgeMessage.StateErrorMessage}</Label>}
                {isRegistrationTypeError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.RegistrationTypeErrorMessage}</Label>
                )}
                {isParkingLocationError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.ParkingLocationErrorMessage}</Label>
                )}
                {isParkingPolicyCheckedError && (
                  <Label className={labelPolicyStyle}>{errorBadgeMessage.PolicyCheckErrorMessage}</Label>
                )}
                {isCarpoolError &&
                  carpoolErrors &&
                  carpoolErrors.map((value: CarpoolError, index: number) => {
                    const errorTypes = [
                      {condition: value.memberType, message: carpoolErrorMessage.memberTypeErrorMessage},
                      {condition: value.memberAlias, message: carpoolErrorMessage.aliasErrorMessage},
                      {condition: value.originCity, message: carpoolErrorMessage.originCityErrorMessage},
                      {condition: value.aliasConflict, message: carpoolErrorMessage.aliasConflictErrorMessage},
                    ];
                    const errorLabels = errorTypes
                      .filter(error => error.condition)
                      .map((error, idx) => (
                        <Label key={idx} className={labelChildStyle}>
                          - {getNumberChar(index + 1)} {error.message}
                        </Label>
                      ));

                    return errorLabels.length > 0 ? <>{errorLabels}</> : <div key={index} />;
                  })}
                {isCarpoolError && carpoolCounterError && (
                  <Label className={labelPolicyStyle}>{carpoolErrorMessage.carpoolMemberCountErrorMessage}</Label>
                )}
                {isCarpoolError && carpoolPolicyCheckedError && (
                  <Label className={labelPolicyStyle}>{carpoolErrorMessage.policyCheckErrorMessage}</Label>
                )}
                {isContactNumberError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.ContactNumberErrorMessage}</Label>
                )}
                {isFuelTypeError && <Label className={labelChildStyle}>{errorBadgeMessage.FuelTypeErrorMessage}</Label>}
                {isExpiryDateError && (
                  <Label className={labelChildStyle}>{errorBadgeMessage.ExpiryDateErrorMessage}</Label>
                )}
              </div>
              <IconButton
                iconProps={{iconName: 'ChromeClose'}}
                title="Close"
                ariaLabel="Close"
                className={closeImageStyle}
                onClick={onDismiss}
              />
            </div>
          </MessageBanner>
        </FullWidthContainer>
      </GridStyle>
    </div>
  );
};
