import {useEffect} from 'react';
import {TextField} from '@fluentui/react';
import {IconLabelRenderer} from '../..';
import {IInputField, getStyles} from '.';
import Config from '../../../configs/constants/uiConfig';
import {InputFieldUsageEvents} from '../../../configs/usageEvents';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
import {TelemetryConstants} from '../../../configs';

export const InputField = (props: IInputField): JSX.Element => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(InputFieldUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(InputFieldUsageEvents.root);
    };
  }, [telemetryClient]);

  return (
    <div>
      <TextField
        autoComplete="off"
        type={props.inputType || 'text'}
        name={props.name}
        label={props.label}
        maxLength={props.maxLength}
        styles={props.styles ?? getStyles(props.disabled || false, props.width)}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        description={props.description}
        deferredValidationTime={500}
        required={props.required || false}
        disabled={props.disabled || false}
        onGetErrorMessage={props.onGetErrorMessage}
        errorMessage={props.errorMessage}
        validateOnLoad={props.validateOnLoad || false}
        validateOnFocusIn={props.validateOnFocusIn || false}
        validateOnFocusOut={props.validateOnFocusOut || false}
        onRenderLabel={
          props.IconLabel
            ? () => {
                return props.IconLabel
                  ? IconLabelRenderer({
                      title: props.IconLabel.title,
                      icon: props.IconLabel.icon,
                      label: props.IconLabel.label,
                    })
                  : null;
              }
            : undefined
        }
        onFocus={props.onFocus}
      />
    </div>
  );
};
