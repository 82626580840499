import {
  getTelemetryClient,
  ICarpoolData,
  IIsCarpoolError,
  IMessageBadge,
  IRegisterVehicleDataItem,
  IRegisterVehicleErrorItem,
  RootState,
  setCarpoolError,
  setInfoBadgeState,
  setVehicleRegisterError,
} from '@microsoft/smart-parking-coreui';
import {useDispatch, useSelector} from 'react-redux';
import useCarpoolValidation from '../../customHooks/carpoolErrorValidations/carpoolErrorValidation';
import {useEffect} from 'react';
import Config from '../../configs/constants/uiConfig';
import {MessageBadgeUsageEvents, TelemetryConstants} from '../../configs';
import {ErrorBadge, InfoBadge} from '../../components';
import AppRoute from '../../route/AppRoute';
export interface IHomeScreenProps {
  enableAdmin: boolean;
  areReportsEnabled: boolean;
}
export const HomeScreen: React.FC<IHomeScreenProps> = (props: IHomeScreenProps) => {
  const dispatch = useDispatch();

  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const errorState: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const vehicleDetails: IRegisterVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const carpoolChecked = vehicleDetails.isCarpoolChecked;
  const apiResponse: IMessageBadge = useSelector((state: RootState) => state.infoBadge);
  const isCarpoolErrorState: IIsCarpoolError = useSelector((state: RootState) => state.isCarpoolError);
  const {carpoolErrors} = useCarpoolValidation();
  const isCarpoolError =
    carpoolErrors.some(v => v.memberType || v.originCity || v.memberAlias || v.aliasConflict) ||
    (carpoolChecked && (!carpoolDetails?.carpoolPolicyChecked || carpoolDetails?.carpoolMembers.length < 2));
  const {
    isFirstNameError,
    isLastNameError,
    isMakeError,
    isModelError,
    isColorError,
    isYearError,
    isVehicleTypeError,
    isLicensePlateError,
    isRegistrationTypeError,
    isStateError,
    isParkingLocationError,
    isFormSubmit,
    isPolicyCheckedError,
    closeModal,
    isContactNumberError,
    isFuelTypeError,
    isExpiryDateError,
  } = errorState;

  const isFormError =
    isFirstNameError ||
    isLastNameError ||
    isMakeError ||
    isModelError ||
    isColorError ||
    isYearError ||
    isVehicleTypeError ||
    isLicensePlateError ||
    isStateError ||
    isRegistrationTypeError ||
    isParkingLocationError ||
    isPolicyCheckedError ||
    isCarpoolError ||
    isFuelTypeError ||
    isExpiryDateError ||
    isContactNumberError;

  useEffect(() => {
    dispatch(setCarpoolError({...isCarpoolErrorState, isCarpoolError: isCarpoolError ?? false}));
  }, [dispatch, isCarpoolError, isCarpoolErrorState]);

  const {response, message, responseStatus, closeStatusModal} = apiResponse;

  const onDismiss = () => {
    telemetryClient.startTrackEvent(MessageBadgeUsageEvents.dismissButtonClicked);
    dispatch(setVehicleRegisterError({...errorState, closeModal: true}));
    dispatch(
      setInfoBadgeState({
        closeStatusModal: true,
        response: false,
        responseStatus: '',
        message: '',
      }),
    );
    telemetryClient.stopTrackEvent(MessageBadgeUsageEvents.dismissButtonClicked);
  };

  return (
    <>
      {!closeModal && isFormSubmit && isFormError ? (
        <ErrorBadge
          isFirstNameError={isFirstNameError}
          isLastNameError={isLastNameError}
          isMakeError={isMakeError}
          isModelError={isModelError}
          isLicensePlateError={isLicensePlateError}
          isVehicleTypeError={isVehicleTypeError}
          isRegistrationTypeError={isRegistrationTypeError}
          isStateError={isStateError}
          isColorError={isColorError}
          isYearError={isYearError}
          isParkingLocationError={isParkingLocationError}
          isParkingPolicyCheckedError={isPolicyCheckedError}
          onDismiss={onDismiss}
          isCarpoolError={isCarpoolError ?? false}
          carpoolErrors={carpoolErrors}
          carpoolPolicyCheckedError={!carpoolDetails?.carpoolPolicyChecked}
          carpoolCounterError={carpoolDetails?.carpoolMembers.length < 2}
          isContactNumberError={isContactNumberError}
          isFuelTypeError={isFuelTypeError}
          isExpiryDateError={isExpiryDateError}
        />
      ) : (
        !closeStatusModal &&
        response && <InfoBadge messageText={message} messageStatus={responseStatus} onDismiss={onDismiss} />
      )}
      <AppRoute isAdminEnabled={props.enableAdmin} areReportsEnabled={props.areReportsEnabled} />
    </>
  );
};
