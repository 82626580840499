import React, {useEffect} from 'react';
import {Stack, IComboBoxOption, Text, IComboBox, Checkbox} from '@fluentui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {CustomDatePicker, InputField, SelectBox} from '../../common';
import {
  checkboxStyles,
  expiresAfterDatePickerStyles,
  newLicenseCheckBoxStyles,
  VehicleRootStyles,
} from './VehicleDetails.styles';
import {vehicleDetail, emptyString, EmptySpaceString, Motorcycle, TelemetryConstants} from '../../../configs';
import {useDispatch, useSelector} from 'react-redux';
import {VehicleDetailsUsageEvents} from '../../../configs/usageEvents';
import {
  getTelemetryClient,
  IConfigData,
  IFormState,
  IRegisteredVehicleDataItem,
  IRegisterVehicleErrorItem,
  IUser,
  resetVehicleDetailState,
  RootState,
  setRegisterFormState,
  setVehicleDetails,
  setVehicleRegisterError,
  vehicleDetailInitialErrorState,
} from '@microsoft/smart-parking-coreui';
import Config, {ConfigKey} from '../../../configs/constants/uiConfig';
import {
  isRegistrationTypeBorrowed,
  isRegistrationTypePersonal,
  isRegistrationTypeSponsored,
  isRegistrationTypeTemporary,
  isRegistrationTypeVisitor,
  isVehicleTypeMotorcycle,
  noSpecialCharacterAndSpaces,
  onlyNumbers,
  stringsToComboBoxAdapter,
} from '../../../utils';
import {IVehicleDetailsProps} from './VehicleDetails.types';
import {IRegionConfigItem} from '@microsoft/smart-parking-coreui/lib/models/uiModel/RegionConfig';

dayjs.extend(utc);
dayjs.extend(timezone);
export const VehicleDetailsV2 = ({isFormAdmin, setShowChaufferSection}: IVehicleDetailsProps): JSX.Element => {
  const {rootDivStyle, twoColumnStyle, hTextStyle, hdivStyle} = VehicleRootStyles;
  const formFields: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const vehicleFormError: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const vehicleformState: IFormState = useSelector((state: RootState) => state.formState);
  const isFormEdit = vehicleformState.isFormEdit;
  const todayDate = dayjs().format('MM/DD/YYYY');
  const dispatch = useDispatch();
  const user: IUser = useSelector((state: RootState) => state.user);
  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const regionConfig: IRegionConfigItem = useSelector((state: RootState) => state.regionConfig);
  const licenseMaxLength = Number(configData[ConfigKey.LicenseMaxLength] as string);
  const contactNoLength = Number(configData[ConfigKey.ContactNoLength] as string);
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  useEffect(() => {
    dispatch(setVehicleRegisterError({...vehicleDetailInitialErrorState}));
    dispatch(setVehicleDetails({...resetVehicleDetailState, registrationId: ''}));
  }, [dispatch]);
  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(VehicleDetailsUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(VehicleDetailsUsageEvents.root);
    };
  }, [telemetryClient]);

  const handleInputChange = (event: any, fieldName: any, options?: any) => {
    event.preventDefault();
    setShowChaufferSection && setShowChaufferSection(true);
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    if (options) {
      if (isVehicleTypeMotorcycle(options.text)) {
        setShowChaufferSection && setShowChaufferSection(false);
        dispatch(
          setVehicleDetails({...formFields, model: emptyString, vehicleType: Motorcycle, isCarpoolChecked: false}),
        );
      } else if (isRegistrationTypeVisitor(options.text)) {
        dispatch(setVehicleDetails({...formFields, isCarpoolChecked: false, [fieldName]: options.text}));
      } else {
        dispatch(setVehicleDetails({...formFields, [fieldName]: options.text}));
      }
      return;
    }
    if (fieldName === vehicleDetail.licensePlate.name || fieldName === vehicleDetail.permitNumber.name) {
      noSpecialCharacterAndSpaces(event.target.value) &&
        dispatch(setVehicleDetails({...formFields, [fieldName]: event.target.value}));
    } else if (fieldName === vehicleDetail.contactNumber.name) {
      //update the field only if the input is a number or empty string
      const inputContactNo = onlyNumbers(event.target.value) ? event.target.value : formFields.contactNumber;
      //dispatch event only if there is a change
      inputContactNo !== formFields.contactNumber &&
        dispatch(setVehicleDetails({...formFields, [fieldName]: inputContactNo}));

      if (inputContactNo.length === contactNoLength || inputContactNo.length === 0) {
        vehicleFormError.isContactNumberError &&
          dispatch(setVehicleRegisterError({...vehicleFormError, isContactNumberError: false}));
      } else {
        dispatch(setVehicleRegisterError({...vehicleFormError, isContactNumberError: true}));
      }
    } else {
      dispatch(setVehicleDetails({...formFields, [fieldName]: event.target.value}));
    }
  };

  const handleDateChange = (date: Date | null | undefined) => {
    if (!date) return;
    vehicleFormError.isExpiryDateError &&
      dispatch(setVehicleRegisterError({...vehicleFormError, isExpiryDateError: false}));
    const selectedDay = dayjs(date).startOf('day');
    const registrationDay = dayjs(formFields.registrationDate).startOf('day');
    const diffDays = selectedDay.diff(registrationDay, 'day');
    const mimickEvent = {
      preventDefault: () => {},
      target: {
        value: diffDays.toString(),
      },
    };
    handleInputChange(mimickEvent, vehicleDetail.expiresAfter.name);
  };

  const onNewLicenseCheckChange = (event: any) => {
    const checked = event.target.checked;
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    dispatch(
      setVehicleDetails({
        ...formFields,
        licensePlate: checked ? vehicleDetail.licensePlate.newLicensePlate : '',
        isNewLicenseChecked: checked,
      }),
    );
  };
  const onDisabilityChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    dispatch(
      setVehicleDetails({
        ...formFields,
        disability: checked,
      }),
    );
  };
  const onParkingStickerCollectedChecked = (event: any) => {
    const checked: boolean = event.target.checked;
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    dispatch(
      setVehicleDetails({
        ...formFields,
        parkingStickerCollected: checked,
      }),
    );
  };
  useEffect(() => {
    dispatch(
      setVehicleDetails({
        ...formFields,
        ownerFirstName:
          isRegistrationTypeSponsored(formFields.registrationType) ||
          isRegistrationTypeBorrowed(formFields.registrationType) ||
          isRegistrationTypePersonal(formFields.registrationType) ||
          isFormAdmin
            ? formFields.ownerFirstName
            : user.firstName,
        ownerLastName:
          isRegistrationTypeSponsored(formFields.registrationType) ||
          isRegistrationTypeBorrowed(formFields.registrationType) ||
          isRegistrationTypePersonal(formFields.registrationType) ||
          isFormAdmin
            ? formFields.ownerLastName
            : user.lastName,
        firstName: isFormAdmin ? formFields.firstName : user.firstName,
        lastName: isFormAdmin ? formFields.lastName : user.lastName,
        userAlias: isFormAdmin ? formFields.userAlias : user.userAlias,
        registrationDate: isFormEdit ? formFields.registrationDate : todayDate,
        vehicleType: formFields.vehicleType === 'Unknown' ? emptyString : formFields.vehicleType,
        permitNumber: isFormAdmin
          ? formFields.permitNumber
          : isRegistrationTypeVisitor(formFields.registrationType)
          ? vehicleDetail.permitNumber.valueVisitor
          : vehicleDetail.permitNumber.valueUnassigned,
        expireInDays: isRegistrationTypeTemporary(formFields.registrationType)
          ? Math.max(0, parseInt(formFields.expireInDays ?? '0', 10) || 0).toString()
          : undefined,
      }),
    );
  }, [dispatch, formFields, isFormAdmin, isFormEdit, todayDate, user]);

  return (
    <div>
      <Stack className={hdivStyle}>
        <Text className={hTextStyle}>{vehicleDetail.headerText}</Text>
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <SelectBox
          label={vehicleDetail.vehicleType.label}
          placeholder={vehicleDetail.vehicleType.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.vehicleType.name, option);
            vehicleFormError.isVehicleTypeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isVehicleTypeError: false}));
            option?.text === Motorcycle &&
              vehicleFormError.isModelError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isModelError: false}));
          }}
          options={stringsToComboBoxAdapter(regionConfig.vehicleTypeList)}
          text={formFields.vehicleType}
          allowFreeform={false}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isVehicleTypeError ? EmptySpaceString : emptyString
          }
          required
        />
        <InputField
          inputType={'text'}
          name={vehicleDetail.make.name}
          placeholder={vehicleDetail.make.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.make.name);
            vehicleFormError.isMakeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isMakeError: false}));
          }}
          value={formFields.make}
          required
          validateOnLoad={false}
          IconLabel={{
            title: vehicleDetail.make.title,
            icon: vehicleDetail.make.icon,
            label: vehicleDetail.make.label,
          }}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isMakeError ? EmptySpaceString : emptyString}
        />
        <InputField
          inputType={'text'}
          name={vehicleDetail.model.name}
          placeholder={vehicleDetail.model.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.model.name);
            vehicleFormError.isModelError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isModelError: false}));
          }}
          value={isVehicleTypeMotorcycle(formFields.vehicleType) ? emptyString : formFields.model}
          validateOnLoad={false}
          IconLabel={{
            title: vehicleDetail.model.title,
            icon: vehicleDetail.model.icon,
            label: vehicleDetail.model.label,
          }}
          required={!isVehicleTypeMotorcycle(formFields.vehicleType)}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isModelError ? EmptySpaceString : emptyString}
          disabled={isVehicleTypeMotorcycle(formFields.vehicleType)}
        />
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <InputField
          inputType={'text'}
          name={vehicleDetail.color.name}
          placeholder={vehicleDetail.color.placeHolderV2}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.color.name);
            vehicleFormError.isColorError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isColorError: false}));
          }}
          value={formFields.color}
          required
          validateOnLoad={false}
          IconLabel={{
            title: vehicleDetail.color.title,
            icon: vehicleDetail.color.icon,
            label: vehicleDetail.color.label,
          }}
          errorMessage={vehicleFormError.isFormSubmit && vehicleFormError.isColorError ? EmptySpaceString : emptyString}
        />
        <Stack>
          <InputField
            inputType={'text'}
            maxLength={licenseMaxLength}
            name={vehicleDetail.licensePlate.name}
            placeholder={vehicleDetail.licensePlate.placeHolderV2}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleInputChange(event, vehicleDetail.licensePlate.name);
              vehicleFormError.isLicensePlateError &&
                dispatch(setVehicleRegisterError({...vehicleFormError, isLicensePlateError: false}));
            }}
            value={formFields.licensePlate}
            required
            validateOnLoad={false}
            IconLabel={{
              title: vehicleDetail.licensePlate.title,
              icon: vehicleDetail.licensePlate.icon,
              label: vehicleDetail.licensePlate.labelV2,
            }}
            errorMessage={
              vehicleFormError.isFormSubmit && vehicleFormError.isLicensePlateError ? EmptySpaceString : emptyString
            }
            disabled={formFields.isNewLicenseChecked}
          />
          <Checkbox
            styles={newLicenseCheckBoxStyles}
            data-testid="newLicenseCheck"
            label={vehicleDetail.newLicenseCheckbox.label}
            onChange={event => onNewLicenseCheckChange(event)}
            checked={formFields.isNewLicenseChecked}
          />
        </Stack>
        <SelectBox
          label={vehicleDetail.registrationType.label}
          placeholder={vehicleDetail.registrationType.placeHolder}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.registrationType.name, option);
            vehicleFormError.isRegistrationTypeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isRegistrationTypeError: false}));
          }}
          required
          text={formFields.registrationType}
          options={stringsToComboBoxAdapter(regionConfig.registrationTypeList)}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isRegistrationTypeError ? EmptySpaceString : emptyString
          }
          disabled={isFormAdmin}
        />
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        {(isRegistrationTypeSponsored(formFields.registrationType) ||
          isRegistrationTypeBorrowed(formFields.registrationType) ||
          isRegistrationTypePersonal(formFields.registrationType)) && (
          <InputField
            inputType="text"
            name={vehicleDetail.vehicleOwnerFirstName.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleInputChange(event, vehicleDetail.vehicleOwnerFirstName.name);
              vehicleFormError.isLastNameError &&
                dispatch(setVehicleRegisterError({...vehicleFormError, isLastNameError: false}));
            }}
            placeholder={vehicleDetail.vehicleOwnerFirstName.placeHolder}
            value={formFields.ownerFirstName}
            required={true}
            IconLabel={{
              title: emptyString,
              icon: emptyString,
              label: vehicleDetail.vehicleOwnerFirstName.label,
            }}
            validateOnLoad={false}
            errorMessage={
              vehicleFormError.isFormSubmit && vehicleFormError.isLastNameError ? EmptySpaceString : emptyString
            }
          />
        )}
        {(isRegistrationTypeSponsored(formFields.registrationType) ||
          isRegistrationTypeBorrowed(formFields.registrationType) ||
          isRegistrationTypePersonal(formFields.registrationType)) && (
          <InputField
            inputType="text"
            name={vehicleDetail.vehicleOwnerLastName.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              handleInputChange(event, vehicleDetail.vehicleOwnerLastName.name);
              vehicleFormError.isLastNameError &&
                dispatch(setVehicleRegisterError({...vehicleFormError, isFirstNameError: false}));
            }}
            placeholder={vehicleDetail.vehicleOwnerLastName.placeHolder}
            value={formFields.ownerLastName}
            required={true}
            IconLabel={{
              title: emptyString,
              icon: emptyString,
              label: vehicleDetail.vehicleOwnerLastName.label,
            }}
            validateOnLoad={false}
            errorMessage={
              vehicleFormError.isFormSubmit && vehicleFormError.isFirstNameError ? EmptySpaceString : emptyString
            }
          />
        )}
        {isRegistrationTypeTemporary(formFields.registrationType) && (
          <CustomDatePicker
            className={expiresAfterDatePickerStyles}
            ariaLabel={vehicleDetail.expiresAfterDate.ariaLabel}
            label={vehicleDetail.expiresAfterDate.label}
            placeholder={vehicleDetail.expiresAfterDate.placeholder}
            onSelectDate={handleDateChange}
            openOnClick={true}
            isRequired={true}
            minDate={dayjs(todayDate).add(1, 'day').toDate()}
            maxDate={dayjs(todayDate).add(30, 'day').toDate()}
            value={
              formFields.expireInDays === undefined || formFields.expireInDays === '0'
                ? undefined
                : dayjs
                    .utc(formFields.registrationDate)
                    .local()
                    .add(parseInt(formFields.expireInDays ?? '0', 10), 'day')
                    .toDate()
            }
          />
        )}
      </Stack>
      <Stack horizontal className={rootDivStyle}>
        <SelectBox
          label={vehicleDetail.fuelType.label}
          placeholder={vehicleDetail.fuelType.placeHolder}
          required
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            handleInputChange(event, vehicleDetail.fuelType.name, option);
            vehicleFormError.isFuelTypeError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isFuelTypeError: false}));
          }}
          text={formFields.fuelType.length > 0 ? formFields.fuelType.replace('_', '-') : formFields.fuelType}
          options={stringsToComboBoxAdapter(regionConfig.fuelTypeList)}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isFuelTypeError ? EmptySpaceString : emptyString
          }
        />
        <InputField
          inputType={'text'}
          name={vehicleDetail.contactNumber.name}
          maxLength={contactNoLength}
          placeholder={vehicleDetail.contactNumber.placeHolder}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.contactNumber.name);
          }}
          IconLabel={{
            label: vehicleDetail.contactNumber.label,
            title: vehicleDetail.contactNumber.title,
            icon: vehicleDetail.contactNumber.icon,
          }}
          value={formFields.contactNumber}
          required={false}
          validateOnLoad={false}
          errorMessage={
            vehicleFormError.isFormSubmit && vehicleFormError.isContactNumberError ? EmptySpaceString : emptyString
          }
        />
        <Stack>
          <Checkbox
            styles={checkboxStyles}
            data-testid="disabilityCheck"
            label={vehicleDetail.disability.label}
            onChange={event => onDisabilityChecked(event)}
            checked={formFields.disability}
          />
          <Checkbox
            styles={checkboxStyles}
            data-testid="parkingStickerCheck"
            label={vehicleDetail.parkingStickerCollected.label}
            onChange={event => onParkingStickerCollectedChecked(event)}
            checked={formFields.parkingStickerCollected}
          />
        </Stack>
      </Stack>
      {isFormEdit && (
        <Stack horizontal className={twoColumnStyle}>
          <InputField
            inputType="text"
            name={vehicleDetail.issuedDate.name}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {}}
            placeholder={vehicleDetail.issuedDate.placeHolder}
            value={dayjs.utc(formFields.registrationDate).local().format('MM/DD/YYYY')}
            required={false}
            validateOnLoad={false}
            disabled={true}
            IconLabel={{
              label: vehicleDetail.issuedDate.placeHolder,
              title: emptyString,
              icon: emptyString,
            }}
          />
        </Stack>
      )}
    </div>
  );
};
