import React, {useCallback, useEffect, useState} from 'react';
import {IComboBox, IComboBoxOption, SpinnerSize} from '@fluentui/react';
import {SiteHeader, SiteFooter, SiteStyles, SpinLoader, DataGridStyles, SelectBox} from '../components';
import {useDispatch, useSelector} from 'react-redux';
import {TelemetryConstants} from '../configs';
import {PageUsageEvents} from '../configs/usageEvents';
import {
  RootState,
  authHelper,
  getTelemetryClient,
  setUser,
  setEnvironment,
  setRegionConfig,
  IConfigData,
  useGetConfigQuery,
  setConfig,
  setVehicleRegisterError,
  vehicleDetailInitialErrorState,
  setVehicleDetails,
  resetVehicleDetailState,
  registerVehicleInitialState,
  setRegisterFormState,
} from '@microsoft/smart-parking-coreui';
import Config, {ConfigKey, regionSelectBox, spinnerMessage} from '../configs/constants/uiConfig';
import {IIdTokenClaims} from './App.types';
import {stringsToComboBoxAdapter} from '../utils';
import {
  defaultRegion,
  defaultRegionConfig,
  getRegionConfigById,
  getRegionConfigByName,
  getRegionNamesAsCSV,
} from '../configs/region/regionConfig';
import {HomeScreen} from '../pages/HomeScreen/HomeScreen';
import {IRegionConfigItem} from '@microsoft/smart-parking-coreui/lib/models/uiModel/RegionConfig';
export interface IConfigDataResponse {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data?: IConfigData;
}
export const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setEnvironment(Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT));
  }, [dispatch]);

  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  const [enableAdmin, setEnableAdmin] = useState(false);
  const [enableReports, setEnableReports] = useState(false);

  const [regionState, setRegionState] = useState<string | undefined>(undefined);
  const configDataResponse: IConfigDataResponse = useGetConfigQuery();
  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const [globalOnboardingFlag, setGlobalOnboardingFlag] = useState(false);
  const [regionConfigArr, setRegionConfigArr] = useState<IRegionConfigItem[]>([]);
  useEffect(() => {
    if (configData && configData[ConfigKey.GlobalOnboardingFeatureFlag]) {
      let globalOnboardingFlag = JSON.parse(configData[ConfigKey.GlobalOnboardingFeatureFlag] as string);
      setGlobalOnboardingFlag(globalOnboardingFlag.enabled);
      setRegionConfigArr([...(JSON.parse(configData[ConfigKey.RegionConfig] as string) as IRegionConfigItem[])]);
    }
  }, [configData]);
  useEffect(() => {
    if (configDataResponse.isSuccess && configDataResponse.data !== undefined) {
      dispatch(setConfig(configDataResponse.data));
    }
  }, [dispatch, configDataResponse]);

  useEffect(() => {
    let config =
      regionState && regionConfigArr
        ? getRegionConfigByName(regionState, regionConfigArr) ?? getRegionConfigById(defaultRegion, regionConfigArr)
        : defaultRegionConfig;
    dispatch(setRegionConfig(config));
    dispatch(setVehicleRegisterError({...vehicleDetailInitialErrorState}));
    dispatch(
      setVehicleDetails({
        ...resetVehicleDetailState,
        registrationId: '',
      }),
    );
    dispatch(
      setRegisterFormState({
        ...registerVehicleInitialState,
        isFormEdit: false,
        isAnyFieldEdited: false,
      }),
    );
  }, [dispatch, regionConfigArr, regionState]);

  const stableDispatch = useCallback((args: any) => dispatch(args), [dispatch]);

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(PageUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(PageUsageEvents.root, window.location.href);
    };
  }, [telemetryClient]);

  useEffect(() => {
    async function fetchGraphDetails() {
      const authResult = await authHelper.getGraphApiAccessToken();
      const idTokenClaims = authResult.idTokenClaims as IIdTokenClaims;
      const adminMatchingClaims = Config.REACT_APP_MSAL_ADMIN_ROLES.filter((role: string) =>
        idTokenClaims['roles']?.includes(role),
      );
      const reportsMatchingClaims = Config.REACT_APP_MSAL_ADMIN_REPORTS_ROLES.filter((role: string) =>
        idTokenClaims['roles']?.includes(role),
      );
      adminMatchingClaims.length > 0 && setEnableAdmin(true);
      reportsMatchingClaims.length > 0 && setEnableReports(true);
      const userDetails = await authHelper.getMsGraphDetails(authResult.accessToken);
      stableDispatch(
        setUser({
          firstName: userDetails.givenName,
          lastName: userDetails.surname,
          userAlias: userDetails.userPrincipalName?.substring(0, userDetails.userPrincipalName?.indexOf('@')),
          country: userDetails.usageLocation,
        }),
      );
      if (regionConfigArr.length > 0) {
        let regionId = !globalOnboardingFlag ? defaultRegion : userDetails.usageLocation;
        setRegionState(getRegionConfigById(regionId, regionConfigArr).regionName);
      }
    }
    fetchGraphDetails();
  }, [stableDispatch, enableAdmin, globalOnboardingFlag, regionConfigArr]);

  return (
    <div>
      {configDataResponse.isLoading ? (
        <SpinLoader
          size={SpinnerSize.large}
          className={DataGridStyles.spinnerStyle}
          label={spinnerMessage.app.loading}
        />
      ) : configDataResponse.isError ? (
        <text>{spinnerMessage.app.error}</text>
      ) : (
        configDataResponse.isSuccess &&
        Object.keys(configData).length !== 0 && (
          <>
            <SiteStyles />
            <SiteHeader>
              {globalOnboardingFlag && (
                <SelectBox
                  label={regionSelectBox.label}
                  placeholder={regionSelectBox.placeHolder}
                  color="white"
                  onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                    if (option) {
                      setRegionState(option.text);
                    }
                  }}
                  text={regionState}
                  options={stringsToComboBoxAdapter(getRegionNamesAsCSV(regionConfigArr))}
                  required
                />
              )}
            </SiteHeader>
            <HomeScreen enableAdmin={enableAdmin} areReportsEnabled={enableReports} />
            <SiteFooter />
          </>
        )
      )}
    </div>
  );
};
