import {Checkbox, Link} from '@fluentui/react';
import {useEffect} from 'react';
import {policyCheckBoxStyles} from '.';
import {TelemetryConstants} from '../../../configs';
import {useDispatch, useSelector} from 'react-redux';
import {PolicyCheckUsageEvents} from '../../../configs/usageEvents';
import {
  getTelemetryClient,
  IFormState,
  IRegisteredVehicleDataItem,
  IRegisterVehicleErrorItem,
  RootState,
  setRegisterFormState,
  setVehicleDetails,
  setVehicleRegisterError,
} from '@microsoft/smart-parking-coreui';
import Config, {policyCheckConst} from '../../../configs/constants/uiConfig';

export const PolicyCheck = (): JSX.Element => {
  const policyCheckError: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const formFields: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const editFormState: IFormState = useSelector((state: RootState) => state.formState);
  const dispatch = useDispatch();
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  const regionConfig = useSelector((state: RootState) => state.regionConfig);

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(PolicyCheckUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(PolicyCheckUsageEvents.root);
    };
  }, [telemetryClient]);

  const _renderLabelWithLink = () => {
    return (
      <span>
        {policyCheckConst.policyCheckText1 + ' '}
        <Link href={regionConfig.policyCheckLink} target="_blank" underline>
          {policyCheckConst.policyCheckText2}
        </Link>
      </span>
    );
  };
  const onCheckChange = (event: any) => {
    const checked = event.target.checked;
    checked && checked === true
      ? dispatch(setVehicleRegisterError({...policyCheckError, isPolicyCheckedError: false}))
      : dispatch(setVehicleRegisterError({...policyCheckError, isPolicyCheckedError: true}));
    dispatch(setVehicleDetails({...formFields, isParkingPolicyChecked: checked}));
    dispatch(setRegisterFormState({...editFormState, isAnyFieldEdited: true}));
  };
  return (
    <Checkbox
      styles={policyCheckBoxStyles}
      data-testid="policyCheckBox"
      onChange={event => onCheckChange(event)}
      onRenderLabel={_renderLabelWithLink}
      checked={formFields.isParkingPolicyChecked}
    />
  );
};
