import {useEffect} from 'react';
import {ConfigKey, emptyString} from '../../../../configs';
import {ICarpoolRegisteredProps} from '.';
import {useGetCarpoolQuery, useGetCarpoolAdminQuery, RootState, IConfigData} from '@microsoft/smart-parking-coreui';
import {useSelector} from 'react-redux';
export const CarpoolRegistered = ({registrationId, updateCarpoolStatus, fromAdmin}: ICarpoolRegisteredProps) => {
  const regionConfig = useSelector((state: RootState) => state.regionConfig);

  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const {data: adminData, isSuccess: isAdminSuccess} = useGetCarpoolAdminQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionConfig.regionId,
      carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || !fromAdmin,
    },
  );
  const {data: userData, isSuccess: isUserSuccess} = useGetCarpoolQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionConfig.regionId,
      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || fromAdmin,
    },
  );
  const {data, isSuccess} = fromAdmin
    ? {data: adminData, isSuccess: isAdminSuccess}
    : {data: userData, isSuccess: isUserSuccess};
  useEffect(() => {
    isSuccess && data ? updateCarpoolStatus(true, registrationId) : updateCarpoolStatus(false, registrationId);
  }, [data, isSuccess, registrationId, updateCarpoolStatus]);

  return <></>;
};
