import {
  IStyle,
  mergeStyleSets,
  getScreenSelector,
  ScreenWidthMinLarge,
  ScreenWidthMaxLarge,
  ScreenWidthMinSmall,
  ScreenWidthMaxSmall,
  ScreenWidthMinMedium,
  ScreenWidthMaxMedium,
  ScreenWidthMinXLarge,
  ScreenWidthMaxXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMaxXXLarge,
  ScreenWidthMinXXXLarge,
  ICheckboxStyles,
  mergeStyles,
} from '@fluentui/react';

export const VehicleRootStyles = mergeStyleSets({
  rootDivStyle: {
    display: 'grid',
    flexFlow: 'row wrap',
    marginLeft: '1%',
    gridTemplateColumns: '4fr 4fr 4fr',
    marginRight: '3%',
    paddingBottom: 20,
    selectors: {
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        marginRight: '2%',
        gridTemplateColumns: '10fr',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginRight: '8%',
        gridTemplateColumns: '10fr',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        gridTemplateColumns: '5fr 5fr',
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginRight: '8%',
        gridTemplateColumns: '4fr 4fr 4fr',
      },
    },
  } as IStyle,

  vehicleDrpStyle: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginLeft: '1%',
    marginRight: '50%',
    paddingBottom: 20,
    selectors: {
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginRight: '41%',
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        marginRight: '50%',
      },
      [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
        marginRight: '52.5%',
      },
    },
  } as IStyle,

  twoColumnStyle: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginLeft: '1%',
    marginRight: '50%',
    selectors: {
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginRight: '41%',
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        marginRight: '50%',
      },
      [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
        marginRight: '52.5%',
      },
    },
  } as IStyle,

  hdivStyle: {
    alignItems: 'flex-start',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '1%',
  } as IStyle,

  drpHidden: {
    visibility: 'hidden',
  } as IStyle,

  dateStyle: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    margin: '10px 0px 0px 10px',
    marginLeft: '1%',
  } as IStyle,
  hTextStyle: {
    fontSize: 18,
    fontWeight: 500,
  } as IStyle,
});

export const newLicenseCheckBoxStyles: ICheckboxStyles = {
  root: {
    alignItems: 'flex-start',
  } as IStyle,
  checkbox: {
    height: '15px',
    width: '15px',
    marginTop: '5px',
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: '3px',
  },
};
export const checkboxStyles: ICheckboxStyles = {
  root: {
    alignItems: 'anchor-center',
    marginTop: '10px',
  } as IStyle,
};

export const expiresAfterDatePickerStyles = mergeStyles({
  selectors: {
    [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
      marginRight: '8%',
    },
    [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
      marginRight: '8%',
    },
    [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
      marginRight: '8%',
    },
    [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
      marginRight: '19%',
    },
    [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
      marginRight: '41%',
    },
    [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
      marginRight: '55%',
    },
  },
});
