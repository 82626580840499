import {
  IImageProps,
  IStyle,
  mergeStyleSets,
  getScreenSelector,
  ScreenWidthMinXLarge,
  ScreenWidthMaxXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMaxXXLarge,
  ScreenWidthMaxSmall,
  ScreenWidthMaxMedium,
  ScreenWidthMinMedium,
  ScreenWidthMinLarge,
  ScreenWidthMaxLarge,
  ScreenWidthMinXXXLarge,
  ScreenWidthMinSmall,
} from '@fluentui/react';
import {SParkTheme} from '../../../theme';
import {headerLogo} from '../../../assets';

export const siteHeaderStyles = mergeStyleSets({
  root: {
    backgroundColor: SParkTheme.palette?.themePrimary,
    display: 'grid',
    gridTemplateColumns: '5fr 2fr',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
    selectors: {
      [getScreenSelector(50, ScreenWidthMaxSmall)]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        gridTemplateColumns: '1fr 0.5fr',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        gridTemplateColumns: '3fr 1.5fr',
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        gridTemplateColumns: '3fr 1.2fr',
      },
      [getScreenSelector(ScreenWidthMinXXXLarge, Number.MAX_SAFE_INTEGER)]: {
        gridTemplateColumns: '3fr 1.4fr',
      },
    },
  } as IStyle,

  siteHeaderImage: {
    display: 'flex',
    position: 'fixed',
    marginTop: 11,
    selectors: {
      [getScreenSelector(50, ScreenWidthMaxSmall)]: {
        marginLeft: 5,
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginLeft: 10,
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        marginLeft: 100,
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginLeft: 150,
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        marginLeft: 230,
      },
      [getScreenSelector(ScreenWidthMaxXXLarge, Number.MAX_SAFE_INTEGER)]: {
        marginLeft: 300,
      },
    },
  } as IStyle,
  textStyle: {
    color: SParkTheme.palette?.white,
    margin: '10px 5px 5px 20px',
    fontSize: '180%',
    opacity: 1,
    fontFamily: 'Segoe UI Semibold',
    selectors: {
      [getScreenSelector(Number.MIN_SAFE_INTEGER, ScreenWidthMinSmall)]: {
        fontSize: '0%',
      },
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        fontSize: '50%',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        fontSize: '100%',
      },
    },
  },
  skipToMainStyles: {
    '@media(max-width: 1300px)': {
      textAlign: 'left',
      width: 'auto',
      padding: '0 2% 0 2%',
    },
  },
});

export const imageProps: IImageProps = {
  src: headerLogo,
};
