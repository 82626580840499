import {IButtonStyles, IStyle, IComboBoxStyles, IComboBoxOption} from '@fluentui/react';
import {ISParkTheme} from '../../../theme';

export const getCaretStyles = (theme: ISParkTheme): Partial<IButtonStyles> => {
  return {
    rootCheckedHovered: {
      backgroundColor: theme.semanticColors.listHeaderBackgroundHovered,
    },
  };
};

export const ContainerComboBoxStyles = (optionsCount: number, color: string | undefined): Partial<IComboBoxStyles> => ({
  container: {
    label: {
      fontWeight: 100,
      color: color ? color : 'default',
    },
    input: {
      cursor: 'pointer',
    },
  },
  root: {
    width: 190,
  } as IStyle,
  optionsContainerWrapper: {
    width: 175,
    height: Math.min(optionsCount * 36, 180),
    overflow: 'auto',
  } as IStyle,
});
export const optionsWithCustomStyling = (options: IComboBoxOption[]): IComboBoxOption[] =>
  options.map((option: IComboBoxOption) => ({
    key: option.key,
    text: option.text,
    styles: {
      optionText: {
        overflow: 'visible',
        whiteSpace: 'normal',
      },
    },
  }));
