import {useEffect} from 'react';
import {Link} from '@fluentui/react';
import {divGap, divRightAllign} from '.';
import {TelemetryConstants} from '../../../configs';
import {HelpUsageEvents} from '../../../configs/usageEvents';
import Config, {ConfigKey, helpLabels} from '../../../configs/constants/uiConfig';
import {getTelemetryClient, IConfigData, RootState} from '@microsoft/smart-parking-coreui';
import {useSelector} from 'react-redux';

export const Help = (): JSX.Element => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const configData: IConfigData = useSelector((state: RootState) => state.config);
  const regionConfig = useSelector((state: RootState) => state.regionConfig);
  const needHelpText = configData[ConfigKey.NeedHelpText] as string;
  const mCommutePugetRegionText = configData[ConfigKey.MCommutePugetRegionText] as string;
  const mCommutePugetSoundRegionHref = configData[ConfigKey.MCommutePugetSoundRegionHref] as string;
  const privacyStatementHref = configData[ConfigKey.PrivacyStatementHref] as string;

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(HelpUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(HelpUsageEvents.root);
    };
  }, [telemetryClient]);
  return (
    <>
      <div className={divGap.root} style={{marginTop: 25}}>
        {needHelpText}
        <Link target="_blank" href={regionConfig.faqLink} underline>
          {helpLabels.parkingRegResFAQ.faqsLinkText}
        </Link>
      </div>
      {!regionConfig.showV2UI && (
        <div className={divGap.root}>
          {mCommutePugetRegionText + ' '}
          <Link target="_blank" href={mCommutePugetSoundRegionHref} underline>
            {helpLabels.msMcommute.mslinkText}
          </Link>
        </div>
      )}
      <div className={divRightAllign.root}>
        <Link target="_blank" href={privacyStatementHref} underline>
          {helpLabels.privacyStatement.linkText}
        </Link>
      </div>
    </>
  );
};
