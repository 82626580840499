import {
  getScreenSelector,
  ScreenWidthMaxLarge,
  ScreenWidthMaxMedium,
  ScreenWidthMaxXLarge,
  ScreenWidthMaxXXLarge,
  ScreenWidthMinLarge,
  ScreenWidthMinMedium,
  ScreenWidthMinSmall,
  ScreenWidthMinXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMinXXXLarge,
} from '@fluentui/react/lib/Styling';
import {FC} from 'react';
import styled from 'styled-components';
import {IGridProps, IFullWidthProps, IHalfWidthProps} from '.';

export const GridStyle: FC<IGridProps> = styled.div<IGridProps>`
   {
    display: grid;
    grid-template-columns: repeat(4, 50px);
    grid-gap: ${(props): number => props.gridGap || 24}px;
    justify-content: center;
    align-items: stretch;
    overflow-y: ${({overflowY = true}): string => (!overflowY ? 'unset' : 'auto')};

    ${getScreenSelector(0, ScreenWidthMinSmall - 1)} {
      grid-template-columns: repeat(4, 50px);
      grid-auto-rows: minmax(0, 528px);
    }

    ${getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)} {
      grid-template-columns: repeat(4, 90px);
    }

    ${getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)} {
      grid-template-columns: repeat(9, 55px);
    }

    ${getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)} {
      grid-template-columns: repeat(8, 94px);
    }

    ${getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)} {
      grid-template-columns: repeat(12, 80px);
    }

    ${getScreenSelector(ScreenWidthMinXXXLarge, 8000)} {
      grid-template-columns: repeat(12, 110px);
    }
  }
`;

// this is an area for a component to render into
export const HalfWidthContainer: FC<IHalfWidthProps> = styled.div<IHalfWidthProps>`
  grid-column-end: span 4;

  ${getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)} {
    grid-column-end: span 8;
  }

  ${getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)} {
    grid-column-end: span 4;
  }

  ${getScreenSelector(ScreenWidthMinXXLarge, 8000)} {
    grid-column-end: span 6;
  }
`;

export const FullWidthContainer: FC<IFullWidthProps> = styled.div<IFullWidthProps>`
  grid-column: 1 / -1;
  justify-self: stretch;
  align-self: stretch;
`;
