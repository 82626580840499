import {addDays, PrimaryButton, Stack, Text} from '@fluentui/react';
import {CustomDatePicker, FullWidthContainer, GridStyle} from '../../components';
import {mainPanelStyles} from '../MainPanel';
import Config from '../../configs/constants/uiConfig';
import {
  ConfigKey,
  initialReportsScreenInputState,
  reportsPanelConst,
  ReportsScreenInputParamConst,
  TelemetryConstants,
} from '../../configs';
import {customReportBarStyle, reportButtonContainerStyles, reportsPanelStyle} from './ReportsScreen.styles';
import {useState} from 'react';
import {IReportsScreenInputState} from './ReportsScreen.types';
import {
  getTelemetryClient,
  IUser,
  RootState,
  useGetConfigQuery,
  useLazyGetCustomCarpoolReportQuery,
  useLazyGetCustomVehicleRegistrationReportQuery,
  useLazyGetFullCarpoolReportQuery,
  useLazyGetFullVehicleRegistrationReportQuery,
} from '@microsoft/smart-parking-coreui';
import {formatDateToISO} from '../../utils';
import {ReportsScreenUsageEvents} from '../../configs/usageEvents/pages/ReportsScreenUsageEvents';
import {useSelector} from 'react-redux';

export const ReportsScreen = () => {
  const {root, stackItemStyles, registerHeadingFontStyles} = mainPanelStyles;
  const [inputValue, setInputValue] = useState<IReportsScreenInputState>(initialReportsScreenInputState);
  const [isDownloading, setIsDowloading] = useState(false);
  const {data: configData = {}} = useGetConfigQuery();
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const [fetchCustomVrReport] = useLazyGetCustomVehicleRegistrationReportQuery();
  const [fetchFullVrReport] = useLazyGetFullVehicleRegistrationReportQuery();
  const [fetchCustomCarpoolReport] = useLazyGetCustomCarpoolReportQuery();
  const [fetchFullCarpoolReport] = useLazyGetFullCarpoolReportQuery();
  const user: IUser = useSelector((state: RootState) => state.user);
  const userAlias = user.userAlias;
  const regionConfig = useSelector((state: RootState) => state.regionConfig);

  const handleDateChange = (name: string, date: Date | null | undefined) => {
    setInputValue({...inputValue, [name]: date});
  };

  const handleCustomVrReportGeneration = () => {
    try {
      setIsDowloading(true);
      fetchCustomVrReport({
        apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
        apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
        regionId: regionConfig.regionId,
        fromDate: formatDateToISO(inputValue.vrFromDate),
        toDate: formatDateToISO(inputValue.vrToDate),
      });
      setIsDowloading(false);
      telemetryClient.trackEvent(ReportsScreenUsageEvents.generateCustomVrReport, {
        fromDate: formatDateToISO(inputValue.vrFromDate),
        toDate: formatDateToISO(inputValue.vrToDate),
        userAlias: userAlias,
      });
    } catch (error) {
      telemetryClient.trackException(
        {exception: error as Error},
        {
          fromDate: formatDateToISO(inputValue.vrFromDate),
          toDate: formatDateToISO(inputValue.vrToDate),
          functionName: 'handleCustomVrReportGeneration',
          userAlias: userAlias,
        },
      );
    }
  };
  const handleFullVrReportGeneration = () => {
    try {
      setIsDowloading(true);
      fetchFullVrReport({
        apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
        apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
        regionId: regionConfig.regionId,
      });
      setIsDowloading(false);
      telemetryClient.trackEvent(ReportsScreenUsageEvents.generateFullVrReport, {
        userAlias: userAlias,
      });
    } catch (error) {
      telemetryClient.trackException(
        {exception: error as Error},
        {
          functionName: 'handleFullVrReportGeneration',
          userAlias: userAlias,
        },
      );
    }
  };
  const handleCustomCarpoolReportGeneration = () => {
    try {
      setIsDowloading(true);
      fetchCustomCarpoolReport({
        carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
        carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
        regionId: regionConfig.regionId,
        toDate: formatDateToISO(inputValue.carpoolToDate),
        fromDate: formatDateToISO(inputValue.carpoolFromDate),
      });
      setIsDowloading(false);
      telemetryClient.trackEvent(ReportsScreenUsageEvents.generateCustomCarpoolReport, {
        fromDate: formatDateToISO(inputValue.carpoolFromDate),
        toDate: formatDateToISO(inputValue.carpoolToDate),
        userAlias: userAlias,
      });
    } catch (error) {
      telemetryClient.trackException(
        {exception: error as Error},
        {
          fromDate: formatDateToISO(inputValue.carpoolFromDate),
          toDate: formatDateToISO(inputValue.carpoolToDate),
          functionName: 'handleCustomCarpoolReportGeneration',
          userAlias: userAlias,
        },
      );
    }
  };
  const handleFullCarpoolReportGeneration = () => {
    try {
      setIsDowloading(true);
      fetchFullCarpoolReport({
        carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
        carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
        regionId: regionConfig.regionId,
      });
      setIsDowloading(false);
      telemetryClient.trackEvent(ReportsScreenUsageEvents.generateFullCarpoolReport, {
        userAlias: userAlias,
      });
    } catch (error) {
      telemetryClient.trackException(
        {exception: error as Error},
        {
          functionName: 'handleFullCarpoolReportGeneration',
          userAlias: userAlias,
        },
      );
    }
  };
  return (
    <Stack className={root}>
      <GridStyle gridGap={6}>
        <FullWidthContainer>
          <Stack.Item align="auto" className={stackItemStyles}>
            <Text className={registerHeadingFontStyles}> {reportsPanelConst.vrReportConsts.headerText}</Text>
            <Stack horizontal className={reportsPanelStyle} tokens={{childrenGap: 10}}>
              <Stack horizontal tokens={{childrenGap: 10}} className={customReportBarStyle}>
                <CustomDatePicker
                  label="From Date"
                  ariaLabel="From Date"
                  placeholder="From Date"
                  value={inputValue.vrFromDate}
                  onSelectDate={date => handleDateChange(ReportsScreenInputParamConst.vrFromDateParam, date)}
                />
                <CustomDatePicker
                  label="To Date"
                  ariaLabel="To Date"
                  placeholder="To Date"
                  value={inputValue.vrToDate}
                  onSelectDate={date => handleDateChange(ReportsScreenInputParamConst.vrToDateParam, date)}
                  minDate={addDays(inputValue.vrFromDate, 1)}
                />
              </Stack>
              <Stack horizontal tokens={{childrenGap: 10}} className={reportButtonContainerStyles}>
                <PrimaryButton
                  title="Generate custom VR report"
                  text={reportsPanelConst.vrReportConsts.customVrReportButtonText}
                  onClick={handleCustomVrReportGeneration}
                  disabled={isDownloading}
                />
                <PrimaryButton
                  title="Generate full VR report"
                  text={reportsPanelConst.vrReportConsts.fullVrReportButtonText}
                  onClick={handleFullVrReportGeneration}
                  disabled={isDownloading}
                />
              </Stack>
            </Stack>
          </Stack.Item>
          {regionConfig.carpoolEnabled && (
            <Stack.Item align="auto" className={stackItemStyles}>
              <Text className={registerHeadingFontStyles}> {reportsPanelConst.carpoolReportConsts.headerText}</Text>
              <Stack horizontal className={reportsPanelStyle} tokens={{childrenGap: 10}}>
                <Stack horizontal tokens={{childrenGap: 10}} className={customReportBarStyle}>
                  <CustomDatePicker
                    label="From Date"
                    ariaLabel="From Date"
                    placeholder="From Date"
                    value={inputValue.carpoolFromDate}
                    onSelectDate={date => handleDateChange(ReportsScreenInputParamConst.carpoolFromDateParam, date)}
                  />
                  <CustomDatePicker
                    label="To Date"
                    ariaLabel="To Date"
                    placeholder="To Date"
                    value={inputValue.carpoolToDate}
                    onSelectDate={date => handleDateChange(ReportsScreenInputParamConst.carpoolToDateParam, date)}
                    minDate={addDays(inputValue.carpoolFromDate, 1)}
                  />
                </Stack>
                <Stack horizontal tokens={{childrenGap: 10}} className={reportButtonContainerStyles}>
                  <PrimaryButton
                    title="Generate custom Carpool report"
                    text={reportsPanelConst.carpoolReportConsts.customCarpoolReportButtonText}
                    onClick={handleCustomCarpoolReportGeneration}
                    disabled={isDownloading}
                  />
                  <PrimaryButton
                    title="Generate full Carpool report"
                    text={reportsPanelConst.carpoolReportConsts.fullCarpoolReportButtonText}
                    onClick={handleFullCarpoolReportGeneration}
                    disabled={isDownloading}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
          )}
        </FullWidthContainer>
      </GridStyle>
    </Stack>
  );
};
