import React from 'react';
import {CoherenceHeader} from '@coherence-design-system/controls';
import {SiteHeaderLabel} from './SiteHeaderLabel';
import {siteHeaderStyles} from './SiteHeader.styles';
import {siteHeaderProps} from '../../../configs';

export const SiteHeaderId = siteHeaderProps.SiteHeaderId;

interface SiteHeaderProps {
  children?: React.ReactNode;
}

export const SiteHeader: React.FC<SiteHeaderProps> = props => {
  const {root, skipToMainStyles} = siteHeaderStyles;

  return (
    <div className={root} id={SiteHeaderId}>
      <CoherenceHeader
        headerLabel={siteHeaderProps.headerLabel}
        appNameSettings={{
          label: <SiteHeaderLabel />,
          ariaLabel: siteHeaderProps.ariaLabel,
        }}
        styles={{
          skipToMainLinkStyles: {
            skipToMainStyles,
          },
        }}
      />
      <div>{props.children}</div>
    </div>
  );
};
