import {IComboBoxOption} from '@fluentui/react';
import {selectBoxDataArray} from '../utils';
import {emptyString} from '../configs';
import {
  IUser,
  IRegisterVehicleDataItem,
  IRegisterVehicleErrorItem,
  IFormState,
  IRegisteredVehicleDataItem,
  IMessageBadge,
} from '@microsoft/smart-parking-coreui';

let currentYear = new Date().getFullYear();
let initialDate = currentYear + 1;
let startDate = 1900;
export const optionsYear: IComboBoxOption[] = selectBoxDataArray(initialDate - startDate, initialDate, false);

export const optionExpireAfter = (startValue: number, maxValue: number): IComboBoxOption[] =>
  selectBoxDataArray(maxValue, startValue, true);

export const optionsVehicleRegistration = {
  optionsYear,
  optionExpireAfter,
};

export const mockVehicleState: IRegisterVehicleDataItem = {
  firstName: 'Donna May',
  lastName: 'Rubio Roman',
  userAlias: 'satri',
  vehicleType: 'Car',
  make: 'Audi',
  model: 'A3',
  year: '2022',
  color: 'Black',
  licensePlate: 'AASD123',
  state: 'WA',
  registrationType: 'Personal',
  parkingLocation: 'STUDIO H',
  permitNumber: 'I6601111',
  registrationDate: '2022-08-04T18:34:47.263',
  expireInDays: '20',
  isParkingPolicyChecked: true,
  isNewLicenseChecked: false,
  ownerFirstName: '',
  ownerLastName: '',
  chaufferContactNumber: '',
  chaufferDrivingLicense: '',
  chaufferName: '',
  fuelType: 'Petrol',
  contactNumber: '123456789',
  disability: false,
  parkingStickerCollected: false,
};

export const mockOwnerState: IUser = {
  firstName: 'Donna May',
  lastName: 'Rubio Roman',
  userAlias: 'satri',
};
export const mockVehicleDetailState: IRegisterVehicleDataItem = {
  vehicleType: 'Car',
  make: 'Audi',
  model: 'A3',
  year: '2022',
  color: 'Black',
  licensePlate: 'AASD123',
  state: 'WA',
  registrationType: 'Personal',
  parkingLocation: 'STUDIO H',
  permitNumber: 'I6601111',
  registrationDate: '2022-08-04T18:34:47.263',
  expireInDays: '20',
  isParkingPolicyChecked: true,
  isNewLicenseChecked: false,
  userAlias: 'test',
  ownerFirstName: '',
  ownerLastName: '',
  chaufferContactNumber: '',
  chaufferDrivingLicense: '',
  chaufferName: '',
  fuelType: 'Petrol',
  contactNumber: '123456789',
  disability: false,
  parkingStickerCollected: false,
};
export const mockYearArray = [
  {key: 2023, text: '2023'},
  {key: 2022, text: '2022'},
  {key: 2021, text: '2021'},
  {key: 2020, text: '2020'},
  {key: 2019, text: '2019'},
  {key: 2018, text: '2018'},
  {key: 2017, text: '2017'},
  {key: 2016, text: '2016'},
  {key: 2015, text: '2015'},
  {key: 2014, text: '2014'},
];
export const mockYear = [
  {key: 0, text: '2023'},
  {key: 1, text: '2022'},
  {key: 2, text: '2021'},
  {key: 3, text: '2020'},
  {key: 4, text: '2019'},
  {key: 5, text: '2018'},
  {key: 6, text: '2017'},
  {key: 7, text: '2016'},
  {key: 8, text: '2015'},
  {key: 9, text: '2014'},
];
export const mockExpireAfter = [
  {key: 0, text: '1'},
  {key: 1, text: '2'},
  {key: 2, text: '3'},
  {key: 3, text: '4'},
  {key: 4, text: '5'},
  {key: 5, text: '6'},
  {key: 6, text: '7'},
  {key: 7, text: '8'},
  {key: 8, text: '9'},
  {key: 9, text: '10'},
];
export const mockvehicleErrorState: IRegisterVehicleErrorItem = {
  isFirstNameError: true,
  isLastNameError: true,
  isVehicleTypeError: true,
  isMakeError: true,
  isModelError: true,
  isColorError: true,
  isYearError: true,
  isLicensePlateError: true,
  isStateError: true,
  isRegistrationTypeError: true,
  isParkingLocationError: true,
  isPolicyCheckedError: false,
  isFormSubmit: false,
  closeModal: false,
  isChaufferContactNumberError: false,
  isChaufferDrivingLicenseError: false,
  isChaufferNameError: false,
  isCountryError: false,
  isContactNumberError: false,
  isFuelTypeError: false,
  isExpiryDateError: false,
};

export const mockformStateInitialState: IFormState = {
  isFormEdit: false,
  isAnyFieldEdited: false,
};
export const mockApiResponseInitialState: IMessageBadge = {
  response: false,
  message: emptyString,
  closeStatusModal: false,
  responseStatus: emptyString,
};

export const mockRegisteredVehicleDataItem: IRegisteredVehicleDataItem[] = [
  {
    color: emptyString,
    expireInDays: '10',
    firstName: emptyString,
    isCarpoolChecked: false,
    isNewLicenseChecked: false,
    lastName: emptyString,
    licensePlate: emptyString,
    make: emptyString,
    model: emptyString,
    parkingLocation: emptyString,
    permitNumber: emptyString,
    registrationDate: emptyString,
    registrationId: emptyString,
    registrationType: emptyString,
    state: emptyString,
    userAlias: emptyString,
    vehicleType: emptyString,
    year: '2022',
    ownerFirstName: emptyString,
    ownerLastName: emptyString,
    chaufferName: emptyString,
    chaufferDrivingLicense: emptyString,
    chaufferContactNumber: emptyString,
    fuelType: 'Petrol',
    contactNumber: '123456789',
    disability: false,
    parkingStickerCollected: false,
  },
];
